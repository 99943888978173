/* eslint-disable no-unused-vars */
import axios from 'axios';
import config from '../config.json';

export const verifyGateway = async (data) => {
    try {
        const response = await axios.post(`${config.DIRECT_DEBIT_API_URL}/verify/gateway`, data);
        console.log('verifyGateway', response.data);
        return response.data;
    } catch (error) {
        console.error('Error verifying gateway:', error);
        throw error; // Propagate the error for handling at a higher level
    }
};