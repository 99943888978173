import { changeConfigSource } from "../../utils";

const Logo = () => {
    const configSource = changeConfigSource()
  
    return (
        <div className="center mt-5">
            <img src={configSource?.logo?.image} style={{ width: '250px' }} alt="logo_image" />
        </div>
    )
}

export default Logo
