import React from 'react'
import { Col, Form, Row } from 'react-bootstrap';
import careersData from '../../datas/careers.json';

const Career = ({ values, setValues, name, isError }) => {

    return (
        <div>
            <Row>
                <Col xs={values.careerType === "อื่นๆ" ? 4 : 12} style={{ padding: 5 }}>
                    <Form.Group className="mb-2">
                        <Form.Control
                            as="select"
                            size="md"
                            type="text"
                            name={name.careerType}
                            value={values.careerType}
                            onChange={(event) => {
                                const selectedValue = event.target.value;
                                if (selectedValue === "อื่นๆ") {
                                    setValues((prevState) => ({
                                        ...prevState,
                                        [name.careerType]: event.target.value,
                                        [name.career]: "",
                                    }))
                                } else {
                                    setValues((prevState) => ({
                                        ...prevState,
                                        [name.careerType]: event.target.value,
                                        [name.career]: event.target.value,
                                    }))
                                }
                            }}
                            isInvalid={isError.err?.careerType || isError.err?.career}
                            style={{ color: values.careerType === "" ? '#6d757d' : '' }}
                        >
                            <option value="" disabled>อาชีพ</option>
                            {
                                Array.from(careersData.careers, (item, index) => {
                                    return <option key={index} value={item}>{item}</option>
                                })
                            }
                            <option value="อื่นๆ">อื่นๆ</option>
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                            {isError.message?.[name.career]}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>

                <Col xs={8} style={{ padding: 5, display: values.careerType === "อื่นๆ" ? "block" : "none" }}>
                    <Form.Group className="mb-2">
                        <Form.Control
                            size="md"
                            placeholder="ระบุอาชีพ"
                            type="text"
                            name={name.career}
                            value={values.career}
                            onChange={(event) =>
                                setValues((prevState) => ({
                                    ...prevState,
                                    [name.career]: event.target.value,
                                }))
                            }
                        />
                    </Form.Group>
                </Col>
            </Row>
        </div >
    )
}

export default Career

// onChange={(event) =>
//     setValues((prevState) => ({
//         ...prevState,
//         radioGroupAddress2: '0',
//         [event.target.name]: event.target.value,
//     }))
// }
