import React, { useEffect } from 'react'
import { Col, Row, Button, Form } from 'react-bootstrap';
import Prefixes from '../../datas/prefixes.json'
import Months from '../../datas/months.json'
import { padLeadingZeros } from '../../../src/utils';
// import { validPhone } from '../../../src/assets/regex';
import Address from './address';

const FormEditor1 = ({ isError, setIsError, values, setValues, setActiveStep, validateForm }) => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const handleSubmit = (event) => {
        event.preventDefault();

        const hasErrorValue = validateForm()
        if (hasErrorValue) return

        setActiveStep((prevActiveStep) => ({ current: prevActiveStep.current + 1, top: prevActiveStep.top + 1 }));
    };

    const handleChange = (event) => {
        const { name, value } = event.target
        const formattedValue = value.replace(/,/g, ''); // ลบจุลภาคออกก่อนเก็บใน state
        setValues((prevState) => ({
            ...prevState,
            [name]: formattedValue,
        }));
    }

    // function formatNumber(value) {
    //     if (!value) return "";
    //     return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    // }

    return (
        <div>
            <Form onSubmit={handleSubmit}>
                <Form.Group>
                    <Row>
                        <Col xs="4" style={{ padding: 5 }}>
                            <Form.Group className="mb-2">
                                <Form.Control
                                    as="select"
                                    size="md"
                                    type="text"
                                    name="prefix"
                                    value={values?.prefix}
                                    onChange={handleChange}
                                    isInvalid={isError.err?.prefix}
                                    style={{ color: values?.prefix === "" ? '#6d757d' : '' }}

                                >
                                    <option value="" disabled>คำนำหน้า</option>
                                    {
                                        Array.from(Prefixes?.prefixes, (item, index) => {
                                            return <option key={index} value={item}>{item}</option>
                                        })
                                    }
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                    <div className="d-flex">
                                        โปรดเลือกคำนำหน้าชื่อ
                                    </div>
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col xs="8" style={{ padding: 5 }}>
                            <Form.Group className="mb-2">
                                <Form.Control
                                    size="md"
                                    placeholder="ชื่อ"
                                    type="text"
                                    name="firstName"
                                    value={values.firstName}
                                    onChange={handleChange}
                                    isInvalid={isError.err?.firstName}
                                />
                                <Form.Control.Feedback type="invalid">
                                    <div className="d-flex">
                                        {isError.message?.firstName}
                                    </div>
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>

                        <Col xs="12" style={{ padding: 5 }}>
                            <Form.Group className="mb-2">
                                <Form.Control
                                    size="md"
                                    placeholder="นามสกุล"
                                    type="text"
                                    name="lastName"
                                    value={values.lastName}
                                    onChange={handleChange}
                                    isInvalid={isError.err?.lastName}
                                />
                                <Form.Control.Feedback type="invalid">
                                    <div className="d-flex">
                                        {isError.message?.lastName}
                                    </div>
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>

                        <Col xs="4" style={{ padding: 5 }}>
                            <Form.Group className="mb-2">
                                <Form.Control
                                    as="select"
                                    size="md"
                                    placeholder="วัน"
                                    type="text"
                                    name="birthDate"
                                    value={values.birthDate}
                                    onChange={handleChange}
                                    isInvalid={isError.err?.birthDate}
                                    style={{ color: values.birthDate === "" ? '#6d757d' : '' }}

                                >
                                    <option value='' defaultValue disabled>วัน</option>
                                    {
                                        Array.from(Array(31), (e, i) => {
                                            return <option key={i} value={i + 1}>{padLeadingZeros(i + 1, 2)}</option>
                                        })
                                    }
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                    <div className="d-flex">
                                        {isError.message?.birthDate}
                                    </div>
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col xs="4" style={{ padding: 5 }}>
                            <Form.Group className="mb-2">
                                <Form.Control
                                    as="select"
                                    size="md"
                                    placeholder="เดือน"
                                    type="text"
                                    name="birthMonth"
                                    value={values.birthMonth}
                                    onChange={handleChange}
                                    isInvalid={isError.err?.birthMonth}
                                    style={{ color: values.birthMonth === "" ? '#6d757d' : '' }}
                                >
                                    <option value='' defaultValue disabled>เดือน</option>
                                    {
                                        Array.from(Array(12), (item, index) => {
                                            return <option key={index} value={index + 1}>{Months.months[index]}</option>
                                        })
                                    }
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                    <div className="d-flex">
                                        {isError.message?.birthMonth}
                                    </div>
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col xs="4" style={{ padding: 5 }}>
                            <Form.Group className="mb-2">
                                <Form.Control
                                    as="select"
                                    size="md"
                                    placeholder="ปีเกิด"
                                    type="text"
                                    name="birthYear"
                                    value={values.birthYear}
                                    onChange={handleChange}
                                    isInvalid={isError.err?.birthYear}
                                    style={{ color: values.birthYear === "" ? '#6d757d' : '' }}
                                >
                                    <option value='' defaultValue disabled>ปีเกิด</option>
                                    {
                                        Array.from(Array(66), (e, i) => {
                                            return <option key={i} value={2550 - 543 - i}>{2550 - i}</option>
                                        })
                                    }
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                    <div className="d-flex">
                                        {isError.message?.birthYear}
                                    </div>
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col xs="12" style={{ padding: 5 }}>
                            <Form.Group className="mb-2">
                                <Form.Control
                                    size="md"
                                    placeholder="เลขบัตรประชาชน"
                                    type="number"
                                    name="identity"
                                    value={values.identity}
                                    onChange={handleChange}
                                    isInvalid={isError.err?.identity}
                                    disabled={values.haveData}
                                />
                                <Form.Control.Feedback type="invalid">
                                    <div className="d-flex">
                                        {isError.message?.identity}
                                    </div>
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>

                        <Col xs="4" style={{ padding: 5 }}>
                            <Form.Group className="mb-2">
                                <Form.Control
                                    as="select"
                                    size="md"
                                    name="gen_der"
                                    value={values?.gen_der}
                                    onChange={handleChange}
                                    isInvalid={isError?.err?.gen_der}
                                    style={{ color: values?.gen_der === "" ? '#6d757d' : "" }}
                                >
                                    <option value="" disabled>เพศ</option>
                                    <option value="ชาย">ชาย</option>
                                    <option value="หญิง">หญิง</option>
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                    <div className="d-flex">
                                        {isError?.message?.gen_der}
                                    </div>
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        
                        <Col xs="8" style={{ padding: 5 }}>
                            <Form.Group className="mb-2">
                                <Form.Control
                                    as="select"
                                    size="md"
                                    name="residence"
                                    value={values?.residence || ""}
                                    onChange={handleChange}
                                    isInvalid={isError?.err?.residence}
                                    style={{
                                        color: !values?.residence ? "#6d757d" : ""
                                    }}
                                >
                                    <option value="" disabled>สถานที่อยู่อาศัย</option>
                                    <option value="เช่าอาศัย">เช่าอาศัย</option>
                                    <option value="อาศัยคนอื่น / พักฟรี">อาศัยคนอื่น / ฟรี</option>
                                    <option value="เจ้าบ้านปลอดภาระ">เจ้าบ้านปลอดภาระ</option>
                                    <option value="สวัสดิการ">สวัสดิการ</option>
                                    <option value="ที่อยู่อาศัย ติดภาระหนี้'">ที่อยู่อาศัย ติดภาระหนี้</option>
                                    <option value="อื่นๆ">อื่นๆ</option>
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                    <div className="d-flex">
                                        {isError?.message?.residence}
                                    </div>
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>

                        <Col xs="6" style={{ padding: 5 }}>
                            <Form.Group className="mb-2">
                                <Form.Control
                                    size="md"
                                    placeholder="อีเมล"
                                    type="email"
                                    name="email"
                                    value={values.email}
                                    onChange={handleChange}
                                    isInvalid={isError.err?.email}
                                />
                                <Form.Control.Feedback type="invalid">
                                    <div className="d-flex">
                                        {isError.message?.email}
                                    </div>
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col xs="6" style={{ padding: 5 }}>
                            <Form.Group className="mb-2">
                                <Form.Control
                                    size="md"
                                    placeholder="เบอร์มือถือ"
                                    type="tel"
                                    name="phone"
                                    value={values?.phone}
                                    onChange={handleChange}
                                    isInvalid={isError.err?.phone}
                                    disabled
                                />
                                <Form.Control.Feedback type="invalid">
                                    <div className="d-flex">
                                        {isError.message?.phone}
                                    </div>
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>

                        <Col xs="6" style={{ padding: 5 }}>
                            <Form.Group className="mb-2">
                                <Form.Control
                                    size="md"
                                    placeholder="ID Line"
                                    type="text"
                                    name="line"
                                    value={values.line}
                                    onChange={handleChange}
                                    isInvalid={isError.err?.line}
                                />
                                <Form.Control.Feedback type="invalid">
                                    <div className="d-flex">
                                        {isError.message?.line}
                                    </div>
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col xs="6" style={{ padding: 5 }}>
                            <Form.Group className="mb-2">
                                <Form.Control
                                    size="md"
                                    placeholder="Facebook"
                                    type="text"
                                    name="facebook"
                                    value={values.facebook}
                                    onChange={handleChange}
                                    isInvalid={isError.err?.facebook}
                                />
                                <Form.Control.Feedback type="invalid">
                                    <div className="d-flex">
                                        {isError.message?.facebook}
                                    </div>
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>

                    <div style={{ textAlign: 'left' }}>
                        <p style={{ fontWeight: 300, fontSize: 16 }}>ที่อยู่ตามบัตรประชาชน</p>
                    </div>
                    <Address
                        values={{
                            address: values.address,
                            province: values.province,
                            county: values.county,
                            district: values.district,
                            zipcode: values.zipcode
                        }}
                        name={{
                            address: 'address',
                            province: 'province',
                            county: 'county',
                            district: 'district',
                            zipcode: 'zipcode'
                        }}
                        isError={isError}
                        handleChange={handleChange}
                    />

                    <div style={{ textAlign: 'left' }}>
                        <p style={{ fontWeight: 300, fontSize: 16 }}>ประวัติการศึกษา</p>
                    </div>
                    <Row>
                        <Col xs="12" style={{ padding: 5 }}>
                            <Form.Group className="mb-2">
                                <Form.Control
                                    as="select"
                                    size="md"
                                    placeholder="ประวัติการศึกษาสูงสุด"
                                    type="text"
                                    name="educational"
                                    value={values?.educational}
                                    onChange={handleChange}
                                    isInvalid={isError.err?.educational}
                                    style={{ color: values.educational === "" ? '#6d757d' : '' }}
                                >
                                    <option value='' disabled>ประวัติการศึกษาสูงสุด</option>
                                    <option value='ปวช'>มัธยมศึกษาตอนปลาย/ปวช.</option>
                                    <option value='ปวส'>อนุปริญญา/ปวส.</option>
                                    <option value='ปต'>ปริญญาตรี</option>
                                    <option value='ปท'>ปริญญาโทขึ้นไป</option>
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                    <div className="d-flex">
                                        {isError.message?.educational}
                                    </div>
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>

                    <div className="mt-5">
                        <Row>
                            <Button variant="secondary" type="submit" style={{ borderRadius: '10px' }}>ต่อไป</Button>
                            <Button variant="danger" style={{ borderRadius: '10px', marginTop: '20px', display: values.haveData ? '' : 'none' }} onClick={() => window.history.back()}>ยกเลิกแก้ไขข้อมูล</Button>
                        </Row>
                    </div>
                </Form.Group>
            </Form >
        </div>
    )
}

export default FormEditor1
