/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */

import { useEffect, useState, useRef } from "react";
import { Col, Row, Carousel, Button, ProgressBar, Modal, Form, Nav, Navbar, Container, NavItem, NavLink, InputGroup } from 'react-bootstrap';
import { Link, useNavigate } from "react-router-dom";

import Logo1 from '../../assets/images/1.png'
import Header from './../../components/header/normal_logo'

function Summary() {
    let navigate = useNavigate();

    const [orientation, setOrientation] = useState(window.orientation);
    useEffect(() => {
        const handleOrientationChange = () => {
            const newOrientation = window.orientation || window.screen.orientation.angle;
            setOrientation(newOrientation);
        };

        window.addEventListener("orientationchange", handleOrientationChange);
        return () => {
            window.removeEventListener("orientationchange", handleOrientationChange);
        };
    }, []);

    const handleClick = () => {
        navigate('/direct_debit/register')
    }

    return (
        <div className="container">
            <div className="center">
                <Header/>
                <br />

                <Row>
                    <Col xs={4}> <img src={Logo1} alt="status" width='60' /></Col>
                    <Col sx={8} className="center" style={{ backgroundColor: '', textAlign: 'left' }}>
                        <h4 style={{ color: 'whitesmoke' }}>ลงทะเบียนสำเร็จ</h4>
                        <p style={{ color: 'whitesmoke' }}></p>
                    </Col>
                </Row>

                <div className={orientation === 90 ? 'm-5' : 'footer'}>
                    <Row >
                        <Button variant="secondary" onClick={() => handleClick()}>เรียบร้อย</Button>
                    </Row>
                </div>
            </div >
        </div >
    )
}

export default Summary;
