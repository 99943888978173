/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */

import { useEffect, useState, useRef } from "react";
// import { Typeahead } from 'react-bootstrap-typeahead';

import { Col, Row, Carousel, Button, ProgressBar, Modal, Form, Nav, Navbar, Container, NavItem, NavLink } from 'react-bootstrap';
import { Link, useNavigate } from "react-router-dom";
// import { useSelector, useDispatch } from 'react-redux';
// import { useLiff } from 'react-liff';
// import InputGroup from 'react-bootstrap/InputGroup';
// import { padLeadingZeros } from '../utils';
// import provincesData from '../datas/provinces.json';

import Header from './../../components/header/small_logo'

// import * as userHttpServices from '../services/digital-app-services';
// import { Formik } from 'formik';
// import * as Yup from "yup";

function Home() {
    let navigate = useNavigate();
    const [pdpa, setPdpa] = useState(false)

    // orientation
    const [orientation, setOrientation] = useState(window.orientation);
    useEffect(() => {
        const handleOrientationChange = () => {
            const newOrientation = window.orientation || window.screen.orientation.angle;
            setOrientation(newOrientation);
        };

        window.addEventListener("orientationchange", handleOrientationChange);
        return () => {
            window.removeEventListener("orientationchange", handleOrientationChange);
        };
    }, []);

    useEffect(() => {
    }, []);

    const handleConsent = () => {
        navigate('/direct_debit/end')
    }

    if (false) {
        return
    } else {
        return (
            <>
                <div className="mobile">
                    <div className="p-3">
                        <div className="center" >
                            <p style={{ textDecoration: "underline" }}>การยินยอมในการเก็บรวบรวม/ใช้งาน/เปิดเผยข้อมูลส่วนบุคคล</p>
                            <Header/>
                        </div>
                        <p style={{ marginBottom: '5rem' }}>
                            หนังสือให้ความยินยอมในการประมวลผลข้อมูลส่วนบุคคล ("หนังสือๆ นี้ จัดทำขึ้นไดย บริษัท เอ็ม เอส ดับเบิลยู จำกัด ("บริษัทฯ") เผื่อให้การเก็บรวบรวม ใช้หรือเปิดเพย
                            มวลผล) ข้อมูลส่วนบุคคลของเจ้าของข้อมูลส่นบุคคลป็นไปตามพระราชบัญญัติคุ้มครองอมูลส่วนบุคคล พ.ศ.2562 โดยบริษัทฯ ประสงค์จะได้รับความยินย
                            ส่วนบุคคลไปดำเนินการประมวลผล หากท่นยินยอมให้ข้อมูลส่วนบุคคลใดของท่านกับทางบริษัทฯ ในฐานะ "ลูกค้า" ยินยอมให้ บริษัท เอ็ม เอส ซี ดับเบิลยู จำกัด ดำเนินการ จัดเก็บ รวมรวม ใช้หรือเปิดเผยข้อมูลส่วนบุคคลท่าน
                            ตามวัตถุประสงค์หรือเพื่อใช้ในกิจกรรมดังต่อไปนี้
                            <br />
                            <br />
                            ส่วนที่ 1 ให้บริษัทฯ ทำการใช้ข้อมูลส่วนบุคคล ได้แก่ เบอร์โทรศัพท์ อีเมล เป็นต้น
                            วัดถุประสงค์: เพื่อวัตถุประสงค์ทางการตลาด ได้แก่ การนำเสนอผลิตภัณฑ์ หรือบริการใหม่ การส่งข้อความเกี่ยวกับโปรโมชั่นการจัดรายการส่งเสริมการขาย โฆษณา ประชาสัมพันธ์ข่าวสาร เกี่ยวกับสินค้า และสิทธิพิเศษต่างๆ ให้กับท่าน
                            <br />
                            <br />
                            (กรณีที่ท่านไม่ให้ยินยอมท่านอาจจะไม่ได้รับข้อมูลข่าวสาร หรือข้อมูลผลิตภัณฑ์ หรือบริการใหม่ที่ท่าน
                            อาจสนใจจากทางบริษัทฯข้อมูลส่วนบุคคลที่มีความอ่อนไหว (Sensitive Data)
                            <br />
                            <br />
                            ส่วนที่ 2 ให้บริษัทฯ ทำการเก็บรวบรวม และใช้ข้อมูลส่วนบุคคลที่มีความอ่อนไหวของท่าน ได้แก่ ศาสนา เชื้อชาติที่ปราฎในสำเนาบัตรประชาชน สำเนาทะเบียนบ้านและเอกสารประกอบการสมัครสินเชื่อ เป็นต้น)
                            วัตถุประสงค์: เพื่อให้บริษัทสามารกใช้เอกสารประกอบการสมัครของท่านในขั้นตอนการพิจารณา(กรณีที่ท่านไม่ให้ยินยอม ขอให้ท่านดำเนินการขีดฆ่า หรือลบข้อมูล*ดังกล่าวออกจากเอกสารก่อนทำการส่งให้กับบริษัท)
                            <br />
                            <br />
                            ท่านไม่ได้ดำเนินการขีดฆ่าข้อบูลฯดังกล่าวออกจาก
                            เอกสารก่อนส่งให้กับบริษัท บริษัทจะถือว่าท่านได้
                            อนุญาตให้บริษัทสามารถดำเนินการหรือทำลายข้อมูล โดยการขีดฆ่า หรือลบข้อมูลฯดังกล่าว
                            ให้กับท่านได้ทันที
                            <br />
                            <br />
                            หมายเหตุ: ท่านสามารถเลือกให้ความยินยอมทั้งหมด บางส่วน หรือไม่ให้ความยินยอม
                            สามารถดูรายละเอียดเพิ่มเติมเกี่ยวกับวัตถุประสงค์ ระยะเวลาในการใช้ข้อบูลส่วนบุคคล และสิทธิของเจ้าของข้อมูลส่วนบุคคลได้ที่ "ประกาศเกี่ยวกับ บุคคล (Privacy Notice) สำหรับลูกค้า"ตามเอกสารแนนท้ายหนังสือขอความยินยอมฉบับนี้หรือตามประกาศความเป็นส่วนตัวที่บริษัทฯ ประชาสัมพันธ์ไว้บนเว็บไซต์
                            รับทราบประกาศความเป็นส่วนตัวของบริษัทแล้ว และข้าพเจ้ารับทราบว่าข้าพเจ้ามีสิทธิให้ความยินยอมในการเก็บรวบรวม ใช้ และ/หรือ ประสงค์ตามที่ระบุไว้
                            ในหนังสือฉบับนี้ได้ตลอดเวลาโดยแจ้งให้กับบริษัททราบเป็นลายลักษณ์อักษรถึงการถอนความยินยอมเช่นว่นั้นได้ ท่านสามารถยินยอมได้
                            ผ่านช่องทางที่บริษัทกำหนดด้านล่างนี้
                            <br />
                            <br />
                            ส่วนที่ 3 ประกาศความเป็นส่วนตัว ( Privacy NotIce) ได้อ่านและข้าใจประกาศความเป็นส่วนตัว (Privacy Notice) ของบริษัทซึ่งมีรายละเอียด
                            เกี่ยวกับการคุ้มครองข้อมูลส่วนบุคคลสามารถอ่านประกาศความเป็นส่วนตัวของบริษัทฯ ซึ่งเผยแพร่บนเว็บไซค์
                            https://msmoneyspeed.com/privacy-policy-2 หรือ
                        </p>

                        <div className="m-5"></div>
                        <Form>
                            <Form.Check>
                                <Form.Check.Input type="checkbox" onChange={() => setPdpa(!pdpa)} />
                                <Form.Check.Label>
                                    <p>
                                        โดยการเลือก "สร้างบัญชี" แสดงว่าคุณยืนยัน
                                        ที่คุณได้อ่านและยอมรับของ Direct debit
                                        ข้อกำหนดการใช้งานและนโยบายความเป็นส่วนตัว
                                    </p>
                                </Form.Check.Label>
                            </Form.Check>
                        </Form>
                        <br />
                        <Row>
                            <Button variant="secondary" type="submit" disabled={!pdpa} onClick={()=>handleConsent()}>สร้างบัญชี</Button>
                        </Row>
                    </div>
                </div>
            </>
        )
    }
}

export default Home;