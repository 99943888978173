import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import PageFirst from '../components/upload/page1'
import PageSecond from '../components/upload/page2';
import PageThird from '../components/upload/page3';
import Loading from '../components/loading';
import Cookies from 'js-cookie';
import * as userHttpServices from '../services/digital-app-services';

const NewUpload = () => {
    const navigate = useNavigate();
    const [data, setData] = useState()
    const [upload, setUpload] = useState([]);
    const [isLoading, setIsLoading] = useState(true)
    const [step, setStep] = useState(1);
    const [type, setType] = useState()
    const [images, setImages] = useState([]);
    const [count, setCount] = useState(0);
    const { state } = useLocation();
    const status = state && state.accStatus

    const handleBack = () => {
        setStep((prevActiveStep) => prevActiveStep - 1);
        if (step === 3) {
            setImages([])
        } else {
            setType()
        }
    };

    useEffect(() => {
        const lineAccessToken = Cookies.get('lineAccessToken');
        console.log('lineAccessToken', lineAccessToken)

        const initialCounters = {
            id_card_no: 0,
            statement: 0,
            salary: 0,
            house_regis: 0,
            book_bank: 0,
            other: 0,
            auth: 0,
        };

        const initialUpload = [];

        userHttpServices
            .lineLogin(lineAccessToken)
            .then((res) => {
                console.log('res', res);
                const data = res.data.data;
                const accStatus = status || data.detail[0].status;

                console.log('user status:', accStatus);

                if (data.type === 'SC') {
                    navigate('/summary', {
                        state: { status: accStatus },
                    });
                } else {
                    const hasSignature = Object.values(data.file_detail).some((val) =>
                        Object.values(val).includes('signature')
                    );

                    if (!hasSignature) {
                        window.location.href = '/signature';
                    }
                }

                if (accStatus === 0 || accStatus === 1) {
                    if (data.detail[0].status === 2) { // Real Status
                        for (const val of Object.values(data.file_detail)) {
                            const fileType = val.file_type;
                            if (initialCounters.hasOwnProperty(fileType)) {
                                if (val.status !== 3) {
                                    initialCounters[fileType]++;
                                    const counter = initialCounters[fileType];
                                    initialUpload.push({
                                        data: val,
                                        channel: counter,
                                        type: fileType,
                                        typeFile: val.file_name.split('.')[1],
                                    });
                                }
                            }
                        }
                    } else {
                        for (const val of Object.values(data.file_detail)) {
                            const fileType = val.file_type;
                            if (initialCounters.hasOwnProperty(fileType)) {
                                initialCounters[fileType]++;
                                const counter = initialCounters[fileType];
                                initialUpload.push({
                                    data: val,
                                    channel: counter,
                                    type: fileType,
                                    typeFile: val.file_name.split('.')[1],
                                });
                            }
                        }
                    }

                    console.log('initialUpload', initialUpload)
                    setData(data);
                    setUpload(initialUpload);
                    setIsLoading(false);
                }

                else {
                    navigate('/summary', {
                        state: { status: accStatus },
                    });
                }
            })
            .catch((err) => {
                console.log('err: ',err)
                alert('[UD L169] An error occurred:', err)
            })
    }, [navigate, status]);

    const renderPage = (step) => {
        switch (step) {
            case 1:
                return <PageFirst
                    data={data}
                    upload={upload}
                    setUpload={setUpload}
                    count={count}
                    type={type}
                    setType={setType}
                    setStep={setStep}
                />
            case 2:
                return <PageSecond
                    type={type}
                    upload={upload}
                    setUpload={setUpload}
                    setStep={setStep}
                    count={count}
                    setCount={setCount}
                    data={data}
                    setData={setData}
                    setImages={setImages}
                />
            case 3:
                return <PageThird
                    type={type}
                    images={images}
                    handleBack={handleBack}
                />
            default:
            // code block
        }
    }

    if (isLoading) return <Loading />

    return (
        renderPage(step)
    )
}

export default NewUpload
