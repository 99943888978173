import axios from 'axios';
import config from '../config.json';

export const checkStatusRefinance = async (userData) => {
    try {
        const response = await axios.post(config.REFINANCE_API + '/refinance/check', userData, {
            headers: {
                'Content-Type': 'application/json'
            },
        });
        return response.data;
    } catch (error) {
        throw new Error('Error while checking refinance status:', error);
    }
};

export const refinanceApproval = async (userData) => {
    try {
        const response = await axios.post(config.REFINANCE_API + '/refinance/approved', userData, {
            headers: {
                'Content-Type': 'application/json'
            },
        });
        return response.data;
    } catch (error) {
        throw new Error('Error while processing refinance request:', error);
    }
};