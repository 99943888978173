import { useEffect, useState } from "react";
import { Row, Button } from 'react-bootstrap';
import SignatureCanvas from 'react-signature-canvas'
import Cookies from "js-cookie";
import Loading from "../../components/loading";

import * as userHttpServices from '../../services/digital-app-services';

function Signature() {
    const [sign, setSign] = useState()
    const [data, setData] = useState()
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        const lineAccessToken = Cookies.get('lineAccessToken')

        userHttpServices.lineLogin(lineAccessToken)
            .then((res) => {
                console.log(res)
                setIsLoading(false)
                try {
                    let data = res.data?.data
                    for (const val of Object.values(data?.file_detail)) {
                        const hasValue = Object.values(val).includes('signature');
                        if (hasValue) window.location.href = '/upload'
                    }
                    setData(data?.detail[0])
                } catch (err) {
                    console.log(err)
                    alert('ERROR SL30')
                }
            })
            .catch((err) => [
                alert('ERROR SL34')
            ])
    }, []);

    const handleSubmit = async () => {
        if (!sign._sigPad._isEmpty) {
            const response = await fetch(sign.getTrimmedCanvas().toDataURL('image/png'));
            const blob = await response.blob();
            var file = new File([blob], "signature.png");

            const formData = new FormData();
            formData.append(
                "files",
                file
            )
            formData.append(
                "file_name",
                file.name
            )
            formData.append(
                "password",
                undefined
            )
            formData.append(
                "type",
                'signature'
            )
            formData.append(
                "id_card_no",
                data.user_id
            )
            formData.append(
                "contact_no",
                data.contact_id
            )

            userHttpServices.uploadfile(formData)
                .then((res) => {
                    console.log('res', res)
                    window.location.href = '/contract/draft'
                })
                .catch((err) => console.log('err', err))
        } else {
            alert('กรุณาลงชื่อยินยอมยอมรับเงื่อนไข/ข้อกำหนดต่างๆของบริษัท และสัญญาเงินกู้')
        }
    };

    const clearSignature = () => {
        sign.clear()
    }

    if (isLoading) return <Loading />

    return (
        <div className="center" style={{ maxWidth: '48rem' }}>
            <div style={{ padding: '32px' }}>
                <div className="header-subject center" style={{ borderRadius: '50px', maxWidth: '380px' }}>
                    <p className="p-2" style={{ fontWeight: 400, fontSize: 16 }}>
                        ลงนามสัญญาเงินกู้ทางอิเล็กทรอนิกส์
                    </p>
                </div>

                <p style={{ fontWeight: 300, fontSize: 16 }}>เพื่อลงนามสัญญาเงินกู้ทางอิเล็กทรอนิกส์และยอมรับเงื่อนไข/ข้อกำหนดต่างๆ ของบริษัท</p>

                <div className="mt-4 mb-4">
                    <SignatureCanvas
                        penColor='#000'
                        backgroundColor="#fff"
                        canvasProps={{ width: '300px', height: '300px', className: 'sigCanvas center', style: { borderRadius: '10px' } }}
                        ref={data => setSign(data)}
                    />
                </div>

                <div className="mt-4 mb-4">
                    <Button
                        size="sm"
                        className="custom-button-signature p-2"
                        style={{ width: '80px' }}
                        onClick={clearSignature}
                    >
                        แก้ไข
                    </Button>
                </div>

                <p style={{ fontWeight: 300, fontSize: 16 }}>กรุณาลงชื่อยินยอมยอมรับเงื่อนไข/ข้อกำหนดต่างๆของบริษัท และสัญญาเงินกู้</p>

                <Row>
                    <button className="btn btn-secondary mt-4 mb-4" id='submit-upload' onClick={() => handleSubmit()}>เรียบร้อย</button>
                </Row>
            </div>
        </div>
    )
}

export default Signature;
