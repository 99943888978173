import LogoMscw from './../../assets/images/logo_brand/logo_ms.png';
import LogoCashkrub from './../../assets/images/logo_brand/logo_cashkrub.png'
import LogoEasyCapital from './../../assets/images/logo_brand/logo_easy_capital.png'

const Logo = () => {
    const brand = localStorage.getItem("brand");

    return (
        <div className="center" style={{ paddingTop: '50px' }}>
            <img src={LogoMscw} alt="" style={{ width: '150px' }} />
            <span style={{ color: 'white', margin: 5, fontSize: '18px' }}>X</span>
            {
                brand === 'EASY-CAPITAL' ?
                    <img src={LogoEasyCapital} alt="" style={{ width: '150px' }} />
                    :
                    <img src={LogoCashkrub} alt="" style={{ width: '150px' }} />
            }
        </div>
    )
}

export default Logo
