import React from 'react'
import { Stack } from 'react-bootstrap';
import {
    BsFill1CircleFill,
    BsFill2CircleFill,
    BsFill3CircleFill,
    BsFill4CircleFill,
    BsFill5CircleFill,
    BsFill6CircleFill,
} from 'react-icons/bs';
import { TbArrowRight } from "react-icons/tb";
import { changeConfigSource } from '../../utils';

const Stepper = ({ activeStep }) => {
    const configSource = changeConfigSource()

    return (
        <Stack className="mt-4 mb-4 center" style={{ display: 'flex', justifyContent: 'space-between' }} direction="horizontal">
            <BsFill1CircleFill fontSize={'24px'} color={activeStep.current > 0 ? configSource?.step_progress?.icon_color : '#fff'} />
            <TbArrowRight fontSize={'24px'} color={activeStep.current > 1 ? configSource?.step_progress?.icon_color : '#fff'} />
            <BsFill2CircleFill fontSize={'24px'} color={activeStep.current > 1 ? configSource?.step_progress?.icon_color : '#fff'} />
            <TbArrowRight fontSize={'24px'} color={activeStep.current > 2 ? configSource?.step_progress?.icon_color : '#fff'} />
            <BsFill3CircleFill fontSize={'24px'} color={activeStep.current > 2 ? configSource?.step_progress?.icon_color : '#fff'} />
            <TbArrowRight fontSize={'24px'} color={activeStep.current > 3 ? configSource?.step_progress?.icon_color : '#fff'} />
            <BsFill4CircleFill fontSize={'24px'} color={activeStep.current > 3 ? configSource?.step_progress?.icon_color : '#fff'} />
            <TbArrowRight fontSize={'24px'} color={activeStep.current > 4 ? configSource?.step_progress?.icon_color : '#fff'} />
            <BsFill5CircleFill fontSize={'24px'} color={activeStep.current > 4 ? configSource?.step_progress?.icon_color : '#fff'} />
            <TbArrowRight fontSize={'24px'} color={activeStep.current > 5 ? configSource?.step_progress?.icon_color : '#fff'} />
            <BsFill6CircleFill fontSize={'24px'} color={activeStep.current > 5 ? configSource?.step_progress?.icon_color : '#fff'} />
        </Stack>
    )
}

export default Stepper
