import React from "react";
import './App.css';
import { useEffect } from 'react';
import { useLiff } from 'react-liff';
import { Button } from "react-bootstrap";
import * as userHttpServices from './services/digital-app-services';
import Cookies from 'js-cookie';
import Header from './components/header/normal_logo'

function App() {
  const { error, liff, isLoggedIn, ready } = useLiff();

  const controller = (type) => {
    const Map = {
      '01': '/home',
      '02': '/prescreen',
      '03': '/upload',
    };

    return Map[type] || '/400';
  };

  // useEffect(() => {
  //   const lineAccessToken = "eyJhbGciOiJIUzI1NiJ9.RrBE9ryWjK6lbDzN6oLsjv8zcSOCIecTOo2zqXgZqPj9t-_pvYb1UvzgKP6VTPdi3FT5JVX550P4bcor__vPyZXkockQsyy02-h4n7sdf8yPgYFAh1JeXnPtB5h2bMdonYOBtYt1cl_dqgrzQguU0UdA95Zdr_GFu-7ME6F278Y.huzYAdYedkY1LJFSnky_q5Iio90PqIId93fwDZTXLL8"
  //   Cookies.set('lineAccessToken', lineAccessToken);
  //   window.location.href = "/home"
  // },[])

  useEffect(() => {
    if (!isLoggedIn) return;

    (async () => {
      const lineAccessToken = liff.getAccessToken()
      Cookies.set('lineAccessToken', lineAccessToken);
      console.log(lineAccessToken)

      userHttpServices.lineLogin(lineAccessToken)
        .then((res) => {
          console.log('lineAccessToken at ok', lineAccessToken)
          console.log('res', res)

          // let loginData = res.data.data
          localStorage.clear()
          localStorage.setItem("brand", res?.data?.brand || res?.data?.data?.brand)
          localStorage.setItem("product", res?.data?.product || res?.data?.detail?.product);
          localStorage.setItem("phone", res?.data?.phone_number);

          window.location.href = controller(res?.data?.message_code)
        })
        .catch(err => {
          console.log('lineAccessToken at err', lineAccessToken)
          alert('[app L53] Error have had been found')
        }
        )
    })();

  }, [liff, isLoggedIn]);

  const showLoginButton = () => {
    if (error) return <p>Something is wrong.</p>;
    if (!ready) return <p>Loading...</p>;

    if (!isLoggedIn) {
      return (
        <Button className="mt-5" variant="select-md" onClick={liff.login}>
          เข้าสู่ระบบ
        </Button>
      );
    } else {
      // return (
      // <>
      // <br />
      // <Button variant="select-md" onClick={liff.login}>
      // เข้าสู่ระบบใหม่
      // </Button>
      // </>
      // )
    }
  };

  return (
    <div className="center" style={{ maxWidth: '30rem' }}>
      <Header />

      {showLoginButton()}
    </div>
  )

}

export default App;