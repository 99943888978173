import React from 'react'
import FormEditor1 from './form-editor-1'
import FormEditor2 from './form-editor-2'
import FormEditor3 from './form-editor-3'
import FormEditor4 from './form-editor-4'
import FormEditor5 from './form-editor-5'
import FormSummary from './form-summary'

const index = ({
    activeStep,
    setActiveStep,
    isError,
    setIsError,
    values,
    setValues,
    handleRegister,
    validateForm1,
    validateForm2,
    validateForm3,
    validateForm4,
    validateForm5,
    isLoading
}) => {
    if (activeStep.current === 1) {
        return (
            <FormEditor1
                isError={isError}
                setIsError={setIsError}
                values={values}
                setValues={setValues}
                setActiveStep={setActiveStep}
                validateForm={validateForm1}
            />
        )
    }
    else if (activeStep.current === 2) {
        return (
            <FormEditor2
                isError={isError}
                setIsError={setIsError}
                values={values}
                setValues={setValues}
                setActiveStep={setActiveStep}
                validateForm={validateForm2}
            />
        )
    }
    else if (activeStep.current === 3) {
        return (
            <FormEditor3
                isError={isError}
                setIsError={setIsError}
                values={values}
                setValues={setValues}
                setActiveStep={setActiveStep}
                validateForm={validateForm3}
            />
        )
    }
    else if (activeStep.current === 4) {
        return (
            <FormEditor4
                isError={isError}
                setIsError={setIsError}
                values={values}
                setValues={setValues}
                setActiveStep={setActiveStep}
                validateForm={validateForm4}
            />
        )
    }
    else if (activeStep.current === 5) {
        return (
            <FormEditor5
                isError={isError}
                setIsError={setIsError}
                values={values}
                setValues={setValues}
                setActiveStep={setActiveStep}
                validateForm={validateForm5}
            />
        )
    } else {
        return (
            <FormSummary
                isError={isError}
                setIsError={setIsError}
                values={values}
                setValues={setValues}
                setActiveStep={setActiveStep}
                handleRegister={handleRegister}
                isLoading={isLoading}
            />
        )
    }
}

export default index
