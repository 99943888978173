/* eslint-disable react-hooks/exhaustive-deps */
import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react'
import * as userHttpServices from '../../services/digital-app-services';
import Loading from '../../components/loading';

const PDFJS = window.pdfjsLib;

const ContractDraft = () => {
    const [images, setImages] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [data, setData] = useState()
    const [url, setUrl] = useState()

    const brand = localStorage.getItem('brand')

    const styles = {
        wrapper: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "5px"
        },
        imageWrapper: {
            border: "1px solid rgba(0,0,0,0.15)",
            borderRadius: "3px",
            boxShadow: "0 2px 5px 0 rgba(0,0,0,0.25)",
            padding: "0"
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            // const lineAccessToken = "eyJhbGciOiJIUzI1NiJ9.HpDSdkR76ekFGKk9f9jwnMVCVeco94lms2vpLn9zAX11JKDknFwNWGNHxV0uXX0EbOzTXWyI1UEI0-9cTO6Fn1JDWJlgxJljhAIsfU_Gp0kYqTlSFPRXS-9yWuOsL1oSV54KV227hOXcvWQAn9oAFOa0N8Eu5oQ17L0pmfPj9SY.U8iNaSGovP-2o-nJ0o--csltrUpeIxC1SiDLa-Gf0y0"

            const lineAccessToken = Cookies.get('lineAccessToken');
            await handleLineLogin(lineAccessToken);
        };

        const handleLineLogin = async (lineAccessToken) => {
            try {
                const res = await userHttpServices.lineLogin(lineAccessToken);
                console.log('Line Login Response:', res);
                setData(res.data.data);
            } catch (error) {
                console.error("Error occurred during Line login:", error);
                alert('[HRL36] An error occurred during Line login.');
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        if (!data) return
        console.log('Data has been updated:', data);

        const fetchData = async () => {
            await userContractDraft();
        };

        const userContractDraft = async () => {
            const userData = {
                contact_id: data?.detail[0]?.contact_id,
                user_id: data?.detail[0]?.user_id,
                type: 'MSCW',
                brand: brand ?? 'MSCW'
            };

            try {
                const res = await userHttpServices.userContractDraft(userData);
                console.log('Contract url response:', res);
                setUrl(res.data)
            } catch (error) {
                console.error('Error while fetching contract url response::', error);
            }
        };

        fetchData();
    }, [data]);


    useEffect(() => {
        if (!url) return
        console.log('URL has been updated:', url);

        PDFJS.getDocument({ url: url })
            .then(_PDF_DOC => {
                console.log('_PDF_DOC', _PDF_DOC);
                renderPage(_PDF_DOC);
            })
            .catch(error => {
                console.error(error);
                alert(error);
                // incorrect password
                // error is an object having 3 properties: name, message & code
            });
    }, [url]);

    async function renderPage(pdf) {
        const imagesList = [];
        const canvas = document.createElement("canvas");
        const scale = 2; // ค่า scale เพิ่มขึ้นเพื่อให้ภาพชัดขึ้น
        for (let i = 1; i <= pdf.numPages; i++) {
            const page = await pdf.getPage(i);
            const viewport = page.getViewport({ scale }); // ปรับ scale ตามต้องการ
            canvas.height = viewport.height;
            canvas.width = viewport.width;
            const render_context = {
                canvasContext: canvas.getContext("2d"),
                viewport: viewport
            };
            await page.render(render_context).promise;
            const img = canvas.toDataURL("image/png");
            imagesList.push(img);
        }
        console.log('imagesList', imagesList)
        setImages(imagesList);
        setIsLoading(false);
    }

    const handleSubmit = async () => {
        window.location.href = '/upload'
    }

    if (isLoading) return <Loading />

    return (
        <div className="center" style={{ maxWidth: '48rem' }}>
            <div className='mt-4'>
                <div className="header-subject center" style={{ borderRadius: '50px', width: '250px' }}>
                    <p className="p-2" style={{ fontWeight: 400, fontSize: 18 }}>
                        หน้าสัญญาฉบับร่าง
                    </p>
                </div>
            </div>

            <div id="image-convas-row">
                <div style={styles.wrapper}>
                    {images.map((image, idx) => (
                        <div key={idx} style={styles.imageWrapper}>
                            <img
                                id="image-generated"
                                src={image}
                                alt="pdfImage"
                                style={{
                                    width: "100%",
                                    height: "100%",
                                    margin: "0",
                                    border: "none"
                                }}
                            />
                        </div>
                    ))}
                </div>
            </div>

            <button className="btn btn-secondary mt-4 mb-4" id='submit-upload' onClick={() => handleSubmit()}>ตรวจสอบหนังสือสัญญาเสร็จสิ้น</button>
        </div>
    )
}

export default ContractDraft
