import { useEffect, useState } from "react";
import { Col, Row, Button, Form, } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';

import * as directDebitHttpServices from '../../services/direct-debit-services';

const thaiBank = [
    "ธนาคารกรุงเทพ (BBL)",
    "ธนาคารกสิกรไทย (KBANK)",
    "ธนาคารกรุงไทย (KTB)",
    "ธนาคารไทยพาณิชย์ (SCB)",
    "ธนาคารกรุงศรีอยุธยา (BAY)",
]

function BankRegister() {
    const lineAccessToken = localStorage.getItem('lineAccessToken');
    const lineLogin = JSON.parse(localStorage.getItem('lineLogin'));
    const userDetail = lineLogin.data.data.detail[0]

    const [selectedBank, setSelectedBank] = useState("")
    const [checkedConsent, setCheckedConsent] = useState(false)
    const [isLoading, setIsLoading] = useState(false);

    const handleSelectedBank = (event) => {
        setSelectedBank(event.target.value);
    };

    const handleRegister = () => {

        if (selectedBank === "") {
            alert("คุณยังไม่ได้ทำการเลือกธนาคาร")
            return;
        }

        // if (!checkedConsent) {
        //     alert("คุณยังไม่ยืนยันการขอใช้บริการ direct debit \nกรุณากดยืนยัน")
        //     return;
        // }

        const object = {
            id_card: userDetail.user_id,
            contract_id: userDetail.contact_id,
            bank: selectedBank
        }

        setIsLoading(true);

        directDebitHttpServices.debitRegister(lineAccessToken, object)
            .then((res) => {
                console.log('res', res);
                if (res.data.success) {
                    window.location.replace(res.data.data);
                } else {
                    alert('[BRL55] Error has been found');
                }
            })
            .catch((err) => {
                alert('[BRL59] Error has been found');
            })
            .finally(() => {
                setIsLoading(false);
            });
    }


    const [isVertical, setIsVertical] = useState(window.innerHeight > window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setIsVertical(window.innerHeight > window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    if (isLoading) {
        return (
            <div className="center">
                <Spinner animation="border" role="status" variant="light" style={{ width: '100px', height: '100px', marginTop: '100px' }} >
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
                <p className="p-3">loading...</p>
            </div>
        )
    }

    return (
        <>
            <div className="mobile">
                <div className="p-3 center" style={{ maxWidth: '30rem' }} >
                    <div className="header" style={{ backgroundColor: "#F15A29", borderRadius: '20px', width: '300px' }}>
                        <p className="p-2" style={{ fontWeight: 300, fontSize: 18, textAlign: 'center' }}>
                            ลงทะเบียน Direct Debit
                        </p>
                    </div>
                    <p style={{ fontWeight: 300, fontSize: 16, textAlign: 'left' }}>ข้อมูลสมาชิก</p>
                    <div>
                        <Row>
                            <Col xs="12" style={{ padding: 5 }}>
                                <Form.Group className="mb-2">
                                    <Form.Select
                                        size="md"
                                        placeholder="ธนาคาร"
                                        type="text"
                                        name="bank"
                                        value={selectedBank}
                                        onChange={handleSelectedBank}
                                    >
                                        <option value='' defaultValue disabled>ธนาคาร</option>
                                        {
                                            Array.from(thaiBank, (e, i) => {
                                                return <option key={i} value={e}>{e}</option>
                                            })
                                        }
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12">
                                <Form.Group>
                                    <Form.Check>
                                        <Form.Check.Input
                                            type="checkbox"
                                            name="consent"
                                            onChange={(event) => setCheckedConsent(event.target.checked)}
                                        />
                                        <Form.Check.Label>
                                            <p style={{ textDecoration: 'underline', textAlign: 'left' }} onClick={() => console.log('ยินยอม direct debit')}>
                                                ข้าพเจ้ายินยอมให้บริษัทเอ็มเอสซีดับเบิลยูจำกัด ใช้บริการ direct debit เพื่อชำระค่างวดสินเชื่อหากคำขอสินเชื่อของข้าพเจ้าได้รับการอนุมัติ
                                            </p>
                                        </Form.Check.Label>
                                    </Form.Check>
                                </Form.Group>
                            </Col>
                        </Row>
                    </div>

                    <div className={`mb-5 ${isVertical ? 'footer' : 'mt-5'}`}>
                        <Row>
                            <Button variant="secondary" onClick={handleRegister} disabled={!checkedConsent ? true : false}>เชื่อมบัญชี Direct Debit</Button>
                        </Row>
                    </div>
                </div>
            </div>
        </>
    )

}

export default BankRegister;