export const validEmail = new RegExp(
    '[A-Za-z0-9]+@[a-z]+.[a-z]{2,3}'
);

export const validPassword = new RegExp(
    '^(?=.*?[A-Za-z])(?=.*?[0-9]).{6,}$'
);

export const validPhone = new RegExp("^(0[689]{1})+([0-9]{8})+$");

export const validCompPhone = new RegExp("^(0)");


