import React from 'react'
import { Modal } from 'react-bootstrap'
import CloseButton from 'react-bootstrap/CloseButton';
import Header from './../../components/header/small_logo'

const Model = ({ show, handleClose }) => {
    return (
        <div>
            <Modal show={show} onHide={handleClose}>
                <Modal.Body>
                    <div className="right">
                        <CloseButton style={{ backgroundColor: '#fff', padding: '10px', marginBottom: '20px', fontSize: '12px' }} onClick={handleClose} />
                    </div>
                    <p className='mb-3' style={{ textAlign: 'center', fontSize: '16px', textDecoration: 'underline' }}>การยินยอมนการรับเงื่อนไขการให้บริการ</p>
                    <Header />
                    <div style={{ marginBottom: '3rem', fontSize: '12px' }}>
                        <p>
                            &emsp;&emsp;ข้าพเจ้าขอรับรองว่าข้อความข้างต้นเป็นความจริงทุกประการรวมทั้งได้รับทราบเงื่อนไขหลักเกณฑ์ที่กำหนดในการใช้บริการสินเชื่อและโดยการลงนามในใบสมัคร (สินเชื่อบุคคล) นี้ เมื่อบริษัทฯ ได้อนุมัติเงินกู้ให้ข้าพเจ้าแล้วข้าพเจ้าตกลงปฏิบัติตามภาระผูกพันที่เกิดขึ้นตามสัญญากู้เงินที่ปรากฎอยู่ในใบสมัคร รวมทั้งข้อกำหนด และเงื่อนไขใดๆ เพิ่มเติมที่บริษัทฯ จะแจ้งให้ทราบในคราวต่อๆไป และให้ถือว่าใบสมัครสินเชื่อบุคคลนี้เป็นส่วนหนึ่งของสัญญากู้เงินสินเชื่อบุคคล) ด้วย
                        </p>
                        <br />
                        <p style={{ border: '1px solid white', padding: '10px', borderRadius: '10px', marginBottom: '5rem' }}>
                            &emsp;&emsp;ข้าพเจ้าได้อ่าน และเข้าใจข้อกำหนดเงื่อนไขต่างๆ ที่เกี่ยวข้องอย่างถี่ถ้วนแล้วพร้อมทั้งได้รับสำเนาสัญญากู้เงิน สินเชื่อบุคคล ไว้เรียบร้อยแล้ว จึงลงลายมือชื่อไว้เป็นหลักฐานและข้อมูล และเอกสารได้นำมาใช้ประกอบการขอสินเชื่อนั้นเป็นเอกสารที่ถูกต้องหากข้อมูลเอกสารหรือคำรับรองใดๆ ของข้าพเจ้าที่ให้ไว้แก่การให้สินเชื่อดังกล่าวไม่ถูกต้องหรือมีการพิสูน์ว่าไม่ตรงความเป็นจริงหรือเข้าใจผิดในสาระสำคัญแล้ว บริษัทฯ มีสิทธิระงับการให้สินเชื่อ หรือบอกเลิก และให้ชำระหนี้เงินต้น ดอกเบี้ย ค่าธรรมเนียม ค่าปรับและจำนวนเงินอื่นใดที่ผู้กู้จะต้องชำระให้บริษัทฯ ภายใต้สัญญากู้เงินฉบับนี้
                        </p>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default Model