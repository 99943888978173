import React from 'react'
import { Modal } from 'react-bootstrap'
import CloseButton from 'react-bootstrap/CloseButton';
import Header from './../../components/header/small_logo'

const Model = ({ show, handleClose }) => {
    return (
        <div>
            <Modal show={show} onHide={handleClose}>
                <Modal.Body>
                    <div className="right">
                        <CloseButton style={{ backgroundColor: '#fff', padding: '10px', marginBottom: '20px', fontSize: '12px' }} onClick={handleClose} />
                    </div>
                    <p className='mb-3' style={{ textAlign: 'center', fontSize: '16px', textDecoration: 'underline' }}>การยินยอมในการเก็บรวบรวม/ใช้งาน/เปิดเผยข้อมูลส่วนบุคคล</p>
                    <Header />
                    <div style={{ marginBottom: '3rem', fontSize: '12px' }}>
                        <p>
                            &emsp;&emsp;หนังสือให้ความยินยอมในการประมวลผลข้อมูลส่วนบุคคล ("หนังสือๆ นี้ จัดทำขึ้นไดย บริษัท เอ็ม เอส ดับเบิลยู จำกัด ("บริษัทฯ") เผื่อให้การเก็บรวบรวม ใช้หรือเปิดเพย ประมวลผล) ข้อมูลส่วนบุคคลของเจ้าของข้อมูลส่วนบุคคลเป็นไปตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ.2562 โดยบริษัทฯ ประสงค์จะได้รับความยินยอมส่วนบุคคลไปดำเนินการประมวลผล หากท่านยินยอมให้ข้อมูลส่วนบุคคลใดของท่านกับทางบริษัทฯ ในฐานะ "ลูกค้า" ยินยอมให้ บริษัท เอ็ม เอส ซี ดับเบิลยู จำกัด ดำเนินการ จัดเก็บ รวมรวม ใช้หรือเปิดเผยข้อมูลส่วนบุคคลท่านตามวัตถุประสงค์หรือเพื่อใช้ในกิจกรรมดังต่อไปนี้
                        </p>
                        <p>
                            ส่วนที่ 1 ให้บริษัทฯ ทำการใช้ข้อมูลส่วนบุคคล ได้แก่ เบอร์โทรศัพท์ อีเมล เป็นต้น<br />
                            วัดถุประสงค์: เพื่อวัตถุประสงค์ทางการตลาด ได้แก่ การนำเสนอผลิตภัณฑ์ หรือบริการใหม่ การส่งข้อความเที่ยวกับโปรโมชั่นการจัดรายการส่งเสริมการขาย โฆษณา ประชาสัมพันธ์ข่าวสาร เกี่ยวกับสินค้า และสิทธิพิเศษต่างๆ ให้กับท่าน
                        </p>
                        <p>
                            กรณีที่ท่านไม่ให้ยินยอมท่านอาจจะไม่ได้รับข้อมูลข่าวสาร หรือข้อมูลผลิตภัณฑ์ หรือบริการใหม่ที่ท่านอาจสนใจจากทางบริษัทฯข้อมูลส่วนบุคคลที่มีความอ่อนไหว (Sensitive Data)
                        </p>
                        <p>
                            ส่วนที่ 2 ให้บริษัทฯ ทำการเก็บรวบรวม และใช้ข้อมูลส่วนบุคคลที่มีความอ่อนไหวของท่าน ได้แก่ ศาสนา เชื้อชาติที่ปรากฎในสำเนาบัตรประชาชน สำเนาทะเบียนบ้านและเอกสารประกอบการสมัครสินเชื่อ เป็นต้น <br />
                            วัตถุประสงค์: เพื่อให้บริษัทสามารกใช้เอกสารประกอบการสมัครของท่านในขั้นตอนการพิจารณา(กรณีที่ท่านไม่ให้ยินยอม ขอให้ท่านดำเนินการขีดฆ่า หรือลบข้อมูลดังกล่าวออกจากเอกสารก่อนทำการส่งให้กับบริษัท)
                        </p>
                        <p>
                            ท่านไม่ได้ดำเนินการขีดฆ่าข้อมูลฯ ดังกล่าวออกจากเอกสารก่อนส่งให้กับบริษัท บริษัทจะถือว่าท่านได้อนุญาตให้บริษัทสามารถดำเนินการหรือทำลายข้อมูล โดยการขีดฆ่า หรือลบข้อมูลฯ ดังกล่าวให้กับท่านได้ทันที
                        </p>
                        <p>
                            หมายเหตุ: ท่านสามารถเลือกให้ความยินยอมทั้งหมด บางส่วน หรือไม่ให้ความยินยอมสามารถดูรายละเอียดเพิ่มเติมเกี่ยวกับวัตถุประสงค์ ระยะเวลาในการเก็บข้อบูลส่วนบุคคล และสิทธิของเจ้าของข้อมูลส่วนบุคคลได้ที่ "ประกาศเกี่ยวกับ บุคคล (Privacy Notice) สำหรับลูกค้าตามเอกสารแนนท้ายหนังสือขอความยินยอมฉบับนี้หรือตามประกาศความเป็นส่วนตัวที่บริษัทฯ ประชาสัมพันธ์ไว้บนเว็บไซต์ รับทราบประกาศความเป็นส่วนตัวของบริษัทแล้ว และข้าพเจ้ารับทราบว่าข้าพเจ้ามีความยินยอมในการเก็บรวบรวม ใช้ และ/หรือ ประสงค์ตามที่ระบุไว้ในหนังสือฉบับนี้ตลอดเวลาโดยแจ้งให้กับบริษัททราบเป็นลายลักษณ์อักษรถึงการถอนความยินยอมเช่นว่านั้นได้ ท่านสามารถดูความยินยอมได้ผ่านช่องทางที่บริษัทกำหนดด้านล่างนี้<br />
                        </p>
                        <p>
                            ส่วนที่ 3 ประกาศความเป็นส่วนตัว (Privacy NotIce) ได้อ่านและเข้าใจประกาศความเป็นส่วนตัว (Privacy Notice) ของบริษัทซึ่งมีรายละเอียดเกี่ยวกับการคุ้มครองข้อมูลส่วนบุคคลสามารถอ่านประกาศความเป็นส่วนตัวของบริษัทฯ ซึ่งเผยแพร่บนเว็บไซต์ <a href="https://msmoneyspeed.com/privacy-policy-2">https://msmoneyspeed.com/privacy-policy-2</a><br />
                        </p>
                    </div>
                    <div className="right">
                        <CloseButton style={{ backgroundColor: '#fff', padding: '10px', marginBottom: '30px' }} onClick={handleClose} />
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default Model