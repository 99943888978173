import { changeConfigSource } from "../../utils";

const Logo = () => {
    const configSource = changeConfigSource()

    return (
        <div className="center mb-3">
            <img src={configSource?.logo?.image} style={{ width: '100px' }} alt="" />
        </div>
    )
}

export default Logo