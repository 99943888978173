import React, { useEffect, useRef, useState } from 'react'
import PNG from '../../assets/images/icon_png.png'
import PDF from '../../assets/images/icon_pdf.png'
import JPG from '../../assets/images/icon_jpg.png'
import JPEG from '../../assets/images/icon_jpeg.png'
import NONE from '../../assets/images/icon_plus.png'
import { PDFDocument } from 'pdf-lib';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { BsFillCheckCircleFill, BsXCircleFill } from "react-icons/bs";
import { Button, Col, Row } from 'react-bootstrap'
import Loading from '../loading';
import Viewer from 'react-viewer';

import * as userHttpServices from '../../services/digital-app-services';

const Page = ({
    type,
    upload,
    setUpload,
    setStep,
    count,
    setCount,
    data,
    setData,
    setImages
}) => {
    const [showOffcanvas, setShowOffcanvas] = useState(false);
    const [offcanvasIndex, setOffcanvasIndex] = useState(0);
    const [isLoading, setIsLoading] = useState(true)
    const [fileURL, setFileURL] = useState();
    const [visible, setVisible] = useState()
    const fileInputRef = useRef(null);
    const PDFJS = window.pdfjsLib;

    const convertStatusType = (type) => {
        const statusMap = {
            'id_card_no': 'id_card_status',
            'statement': 'statement_status',
            'salary': 'salary_status',
            'house_regis': 'house_regis_status',
            'book_bank': 'book_bank_status',
            'auth': 'auth_status',
            'other': 'orther_file_status',
        };

        return statusMap[type] || null;
    };

    const handleBack = () => {
        setStep((prevActiveStep) => prevActiveStep - 1);
        // setImages([])
    };

    const handleButtonClick = (index) => {
        if (handelCheckUpload(index)) {
            handleShowOffcanvas(index)
        } else {
            fileInputRef.current.click();
        }
    };

    // ตรวจสอบ err file classify
    // const classify = () => {
    //     const type1 = localStorage.getItem("type");
    //     // const type2 = type

    //     // if (type1 !== undefined || type1 !== null || type1 !== '') {
    //     //     return type1
    //     // } else if (type2 !== undefined || type2 !== null || type2 !== '') {
    //     //     return type2
    //     // } else {
    //     //     return 'other'
    //     // }
    // }

    const menu = (type) => {
        if (type === 'id_card_no') {
            return 'สำเนาบัตรประชาชน'
        } else if (type === 'statement') {
            return 'รายการเดินบัญชีธนาคาร'
        } else if (type === 'salary') {
            return 'สลิป/หนังสือรับรองเงินเดือน'
        } else if (type === 'house_regis') {
            return 'สำเนาทะเบียนบ้าน'
        } else if (type === 'book_bank') {
            return 'หน้าสมุดบัญชีธนาคาร'
        } else if (type === 'auth') {
            return 'รูปถ่ายคู่บัตรประชาชน'
        } else if (type === 'other') {
            return 'เอกสารอื่นๆ'
        }
    }

    const handleShowOffcanvas = (index) => {
        setOffcanvasIndex(index);
        setShowOffcanvas(true);
    }

    const handleHideOffcanvas = () => setShowOffcanvas(false);

    async function loadPDF(uri, pdf_password) {
        return PDFJS.getDocument({
            url: uri,
            password: pdf_password
        })
            .promise
            .then(function (pdfDoc) {
                console.log("PDF loaded successfully:", pdfDoc);
                return pdfDoc;
            })
            .catch(function (error) {
                console.log("Error loading PDF:", error);
                return error;
            });
    }

    const handelCheckUpload = (channel) => {
        let checker = false
        for (const val of Object.values(upload)) {
            if (val.channel === channel && val.type === type) {
                checker = true
            }
        }

        return checker
    }

    async function checkPDFPassword(file) {
        const arrayBuffer = await file.arrayBuffer();
        const pdfDoc = await PDFDocument.load(arrayBuffer, { ignoreEncryption: true });
        return pdfDoc.isEncrypted
    }

    const miniStatus = (status) => {
        const statusColors = {
            1: '#cccccc', // fail
            2: '#ed1f28', // fail
            3: '#47bb11', // success
        };

        const color = statusColors[status] || '#ffffff';

        if (status === 1) {
            return <BsFillCheckCircleFill color={color} className="icon"
                style={{
                    position: 'absolute',
                    top: '5px',
                    right: '5px',
                }} />
        }
        else if (status === 2) {
            return <BsXCircleFill color={color} className="icon"
                style={{
                    position: 'absolute',
                    top: '5px',
                    right: '5px',
                }} />
        } else if (status === 3) {
            return <BsFillCheckCircleFill color={color} className="icon"
                style={{
                    position: 'absolute',
                    top: '5px',
                    right: '5px',
                }} />
        } else {
            return <BsXCircleFill color={color} className="icon"
                style={{
                    position: 'absolute',
                    top: '5px',
                    right: '5px',
                }} />
        }
    }

    const iconStatus = (channel) => {
        const val = Object.values(upload).find((v) => v.channel === channel && v.type === type);
        if (!val) return miniStatus(0); // Default status when not found

        const typeFile = val.typeFile.toLowerCase();
        if (['png', 'pdf', 'jpg', 'heic', 'heif', 'jpeg'].includes(typeFile)) {
            return miniStatus(val.data.status);
        } else {
            return miniStatus(0); // Default status for unsupported types
        }
    };

    const iconType = (channel) => {
        const val = Object.values(upload).find((v) => v.channel === channel && v.type === type);
        if (!val) return NONE; // Default type when not found

        const typeFile = val.typeFile.toLowerCase();
        const typeMap = {
            png: PNG,
            pdf: PDF,
            jpg: JPG,
            heic: JPG, // Assuming heic and heif are treated as JPG
            heif: JPG,
            jpeg: JPEG,
        };

        // console.log(typeMap[typeFile], typeFile)
        return typeMap[typeFile] || NONE; // Default for unsupported types
    };

    // ใส่เอกสาร
    async function handleFileSelect(event) {
        const selectedFile = event.target.files[0];
        console.log('Selected file:', selectedFile.name);

        let password
        if (event.target.files.length !== 0) {
            const file = event.target.files[0];
            const typeFile = event.target.files[0].type.split('/')[1]
            const fileSize = file.size; // ขนาดไฟล์ของไฟล์ที่อัปโหลด (หน่วยเป็นไบต์)

            if (typeFile === undefined) {
                window.alert('ไฟล์มีปัญหา กรุณาสอบถามเจ้าหน้าที่')

                // return file
                event.target.value = '';
                return;
            }

            // formData
            let formData1 = event.target.files[0]
            let formData2 = event.target.files[0].name

            console.log(file)

            const maxFileSize = 10 * 1024 * 1024; // 10 MB

            // ตรวจสอบขนาดไฟล์
            if (fileSize > maxFileSize) {
                alert('ไฟล์ขนาดใหญ่เกินกว่าที่รองรับ');
                event.target.value = null; // ล้างค่า input field
                return;
            }

            // ต่อไปคือการอัปโหลดไฟล์
            // ...

            // pdf
            if (typeFile.toLowerCase() === 'pdf') {
                const isPasswordProtected = await checkPDFPassword(file);
                console.log('isPasswordProtected', isPasswordProtected)

                if (isPasswordProtected) {
                    password = window.prompt("เอกสารนี้ได้รับการป้องกันด้วยรหัสผ่าน โปรดป้อนรหัสผ่าน:");

                    const uri = URL.createObjectURL(file);
                    const PDFAuth = await loadPDF(uri, password)

                    // PasswordException
                    if (PDFAuth.name === 'PasswordException') {
                        window.alert(PDFAuth.message);

                        // return file
                        event.target.value = '';
                        return;
                    }
                }
            }

            // heic & heif
            // if (typeFile.toLowerCase() === 'heic' || typeFile.toLowerCase() === 'heif') {
            //     setLoading(true)
            //     const heicFile = file// your HEIC file
            //     const pngFile = await heicToJpg(heicFile);
            //     formData1 = pngFile
            //     formData2 = pngFile.name
            // }

            // any
            console.log(typeFile.toLowerCase())

            const t = typeFile.toLowerCase()
            if (t === 'jpeg' || t === 'jpg' || t === 'png' || t === 'pdf') {
            } else {
                window.alert('ไฟล์ประเภท : ' + t + ' ไม่สามารถอัปโหลดได้ \n\nกรุณาอัปโหลดไฟล์ประเภท : JPEG/JPG/PNG/PDF \nขนาดไม่เกิน 10MB')

                // return file
                event.target.value = '';
                return;
            }

            setCount(count + 1)
            const formData = new FormData();
            formData.append(
                "files",
                formData1
            )
            formData.append(
                "file_name",
                formData2
            )
            formData.append(
                "password",
                password
            )
            formData.append(
                "type",
                type
            )
            formData.append(
                "id_card_no",
                data.detail[0].user_id
            )
            formData.append(
                "contact_no",
                data.detail[0].contact_id
            )

            const targetValue = type;
            let countChannel = 1;

            for (const val of Object.values(upload)) {
                if (val.type === targetValue) {
                    countChannel++;
                }
            }

            setUpload([...upload, { data: formData, channel: countChannel, type: type, typeFile: typeFile }])
        }
    }

    async function renderPage(pdf) {
        const imagesList = [];
        const canvas = document.createElement("canvas");
        // let canv = document.querySelector(".canv");
        // setLoading(true)
        for (let i = 1; i <= pdf.numPages; i++) {
            var page = await pdf.getPage(i);
            var viewport = page.getViewport({ scale: 2 });
            canvas.height = viewport.height;
            canvas.width = viewport.width;
            var render_context = {
                canvasContext: canvas.getContext("2d"),
                viewport: viewport
            };
            console.log("page lenght", pdf.numPages);
            await page.render(render_context).promise;
            let img = canvas.toDataURL("image/png");
            imagesList.push(img);
        }
        console.log('imagesList', imagesList)
        setImages(imagesList);
        // setLoading(false)
    }

    const handlePreview = (channel) => {
        // console.log('offcanvasIndex', channel)
        // setVisible(true)
        for (const val of Object.values(upload)) {
            if (val.channel === channel && val.type === type) {
                if (!Boolean(val.data.url)) {
                    // New upload
                    const uri = URL.createObjectURL(Object.fromEntries(val.data).files);
                    const pdf_password = Object.fromEntries(val.data).password

                    if (val.typeFile.toLowerCase() === 'pdf') {
                        PDFJS.getDocument({
                            url: uri,
                            password: pdf_password
                        }).then(function (_PDF_DOC) {
                            console.log('_PDF_DOC', _PDF_DOC)
                            renderPage(_PDF_DOC)
                            setStep(3)
                        }).catch(function (error) {
                            console.log(error)
                            alert(error)
                            // incorrect password
                            // error is an object having 3 properties : name, message & code
                        });
                    } else {
                        setFileURL(uri)
                        setVisible(true)
                    }
                } else {
                    // Old upload
                    const uri = val.data.url
                    const pdf_password = val.data.password
                    if (val.typeFile.toLowerCase() === 'pdf') {
                        PDFJS.getDocument({
                            url: uri,
                            password: pdf_password
                        })
                            .then(function (_PDF_DOC) {
                                console.log('_PDF_DOC', _PDF_DOC)
                                renderPage(_PDF_DOC)
                                setStep(3)
                            }).catch(function (error) {
                                console.log(error)
                                alert(error)
                                // incorrect password
                                // error is an object having 3 properties : name, message & code
                            });
                    } else {
                        setFileURL(uri)
                        setVisible(true)
                    }
                }
            }
        }
        handleHideOffcanvas()
    }

    const handleDelete = (channel) => {
        let active = false;
        const updatedUpload = [...upload]; // Create a copy of upload to avoid mutating it directly

        for (const val of Object.values(upload)) {
            if (val.channel === channel && val.type === type) {
                if (!Boolean(val.data.url)) {
                    // New upload
                    active = true;
                    const index = updatedUpload.findIndex(obj => obj.channel === val.channel && obj.type === val.type);
                    updatedUpload.splice(index, 1);
                    setCount(count - 1);
                } else {
                    // Old upload
                    const object = {
                        user_id: val.data.user_id,
                        contact_id: val.data.contact_id,
                        file_type: val.data.file_type,
                        name: val.data.file_name
                    };

                    userHttpServices.deletefile(object)
                        .then((res) => {
                            console.log('res', res);

                            if (channel === 1) {
                                const statusValue = data.detail[0][convertStatusType(type)] === 0;

                                setData((prevData) => ({
                                    ...prevData,
                                    detail: [
                                        {
                                            ...prevData.detail[0],
                                            [convertStatusType(type)]: statusValue,
                                        },
                                        ...prevData.detail.slice(1),
                                    ],
                                }));
                            }
                        });

                    const index = updatedUpload.findIndex(obj => obj.channel === val.channel && obj.type === val.type);
                    updatedUpload.splice(index, 1);
                    active = true;
                }
            } else if (val.type === type && active) {
                const indexToDecrement = updatedUpload.findIndex(obj => obj.channel === val.channel && obj.type === val.type);
                updatedUpload[indexToDecrement].channel--;
            }
        }


        handleHideOffcanvas();
        setUpload(updatedUpload);
    };

    const checkDelStatus = (offcanvasIndex) => {
        for (const val of Object.values(upload)) {
            if (val.channel === offcanvasIndex && val.type === type && val.data.status === 3) {
                return false
            }
        }
        return true
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsLoading(false);
        }, 500);

        return () => clearTimeout(timer);
    }, []);

    if (isLoading) return <Loading />

    const ButtonGroup = ({ buttonIndices }) => (
        <div className="center" style={{ display: 'flex', flexWrap: 'wrap' }}>
            {buttonIndices.map((buttonIndex) => (
                <div style={{ margin: '5px' }} key={buttonIndex}>
                    <button
                        style={{
                            backgroundImage: `url(${iconType(buttonIndex)})`,
                            backgroundSize: 'cover',
                            width: '70px',
                            height: '70px',
                            position: 'relative',
                            border: 'none',
                            backgroundColor: 'transparent',
                        }}
                        onClick={() => handleButtonClick(buttonIndex)}
                    >
                        {iconStatus(buttonIndex)}
                    </button>
                    <input
                        id={`button${buttonIndex}-file-input`}
                        type="file"
                        ref={fileInputRef}
                        style={{ display: 'none' }}
                        onChange={handleFileSelect}
                        disabled={handelCheckUpload(buttonIndex)}
                    />
                </div>
            ))}
        </div>
    );

    let totalButtons = 9;
    const buttonsPerRow = 3;
    const buttonGroups = [];

    for (let i = 0; i < totalButtons; i += buttonsPerRow) {
        for (const val of Object.values(upload)) {
            if (val.channel === i && val.type === type) {
                totalButtons += 3
            }
        }

        const buttonIndices = Array.from(
            { length: buttonsPerRow },
            (_, index) => i + index + 1
        );
        buttonGroups.push(<ButtonGroup key={i} buttonIndices={buttonIndices} />);
    }

    return (
        <div className='center' style={{ maxWidth: '30rem' }}>
            <div style={{ padding: '45px' }}>
                <div className="header-subject center" style={{ borderRadius: '50px', width: '220px' }}>
                    <p className="p-2" style={{ fontWeight: 400, fontSize: 18 }}>
                        เลือกไฟล์เอกสาร
                    </p>
                </div>

                <p style={{ fontSize: '18px', fontWeight: 400, textAlign: 'center' }}>
                    {menu(type)}
                </p>

                <div
                    className='center'
                    style={{
                        backgroundColor: '#fff',
                        borderRadius: '10px',
                        padding: '30px 0',
                        maxWidth: '300px',
                    }}
                >
                    {buttonGroups}
                </div>

                <br />
                <p style={{ fontSize: 14, color: '#cccccc', textAlign: 'left' }} onClick={() => { window.location.href = '/form' }}>*แก้ไขข้อมูล*</p>

                <p style={{ textAlign: 'left', fontWeight: '20px' }}>
                    *ไฟล์ : JPEG/JPG/PNG/PDF
                    <br />ขนาดไม่เกิน 10MB
                </p>
                <br />

                <div>
                    <Row >
                        <Button id='submitUpload' variant="secondary" style={{}} onClick={() => { handleBack(); console.log('count file upload:', count) }}>เรียบร้อย</Button>
                    </Row>
                </div>
            </div >

            <Offcanvas placement={"bottom"} show={showOffcanvas} onHide={handleHideOffcanvas} style={{ height: 'auto', backgroundColor: 'rgba(255, 255, 255, 1)' }}>
                <Offcanvas.Body>
                    <Row className="p-2">
                        <Col sm={12}>
                            <Button variant="primary" onClick={() => handlePreview(offcanvasIndex)} style={{ width: '100%', padding: 10 }}>
                                Preview image
                            </Button>
                        </Col>
                        <Col sm={12} style={{ marginTop: 15, display: checkDelStatus(offcanvasIndex) ? 'block' : 'none' }}>
                            <Button variant="primary" onClick={() => handleDelete(offcanvasIndex)} style={{ width: '100%', padding: 10 }}>
                                Delete image
                            </Button>
                        </Col>
                    </Row>
                </Offcanvas.Body>
            </Offcanvas>

            <div>
                <Viewer
                    visible={visible}
                    onClose={() => { setVisible(false); }}
                    images={[{ src: fileURL, alt: '' }]}
                    downloadable={true}
                    // rotatable={false}
                    noNavbar={true}
                    noToolbar={true}
                    // noImgDetails={true}
                    scalable={false}
                    changeable={false}
                />
            </div>
        </div >
    )
}

export default Page
