/* eslint-disable react-hooks/exhaustive-deps */
import Header from './../../components/header/normal_logo'
import Success from './../../assets/images/1.png';
import Fail from './../../assets/images/2.png';
import { Row, Button } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import Loading from './../../components/loading';
import { useLiff } from 'react-liff';

import * as refinanceServices from './../../services/refinance-services'
import * as userHttpServices from './../../services/digital-app-services';

export default function Refinance() {
    const { error, liff, isLoggedIn, ready } = useLiff();
    const [isLoading, setIsLoading] = useState(true)
    const [data, setData] = useState()
    const [status, setStatus] = useState(false);

    useEffect(() => {
        if (!isLoggedIn) return;

        const fetchData = async () => {
            const lineAccessToken = liff.getAccessToken();
            await handleLineLogin(lineAccessToken);
        };

        const handleLineLogin = async (lineAccessToken) => {
            try {
                const res = await userHttpServices.lineLoginForRefinance(lineAccessToken);
                console.log('Line Login Response:', res);
                setData(res.data.data);
            } catch (error) {
                console.error("Error occurred during Line login:", error);
                alert('[HRL36] An error occurred during Line login.');
            }
        };

        fetchData();
    }, [isLoggedIn, liff]);

    useEffect(() => {
        if (!data) return
        console.log('Data has been updated:', data);

        const fetchData = async () => {
            await checkRefinanceStatus();
        };

        const checkRefinanceStatus = async () => {
            const userData = {
                contact_id: data?.detail[0]?.contact_id,
                user_id: data?.detail[0]?.user_id
            };
            console.log('userData', userData)

            try {
                const res = await refinanceServices.checkStatusRefinance(userData);
                console.log('Refinance Status:', res);
                setStatus(res.success);
            } catch (error) {
                console.error('Error while fetching refinance status:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [data]);

    const handleClickApproval = async () => {
        const userData = {
            contact_id: data?.detail[0]?.contact_id,
            user_id: data?.detail[0]?.user_id
        };

        if (window.confirm("ยืนยันการขอเพิ่มวงเงิน?")) {
            try {
                const refinanceRes = await refinanceServices.refinanceApproval(userData);
                console.log('Refinance Approval Response:', refinanceRes);

                if (refinanceRes.success) {
                    window.location.href = '/'
                } else {
                    alert('Refinance approval failed');
                }
            } catch (error) {
                console.error('Error while processing refinance approval:', error);
            }
        } else {
            // Code to execute if the user clicks "Cancel"
        }
    };

    const buttonLogin = () => {
        return (
            <Row>
                <Button
                    variant="select-md center mt-4"
                    onClick={liff.login}>
                    เข้าสู่ระบบ
                </Button>
            </Row>
        );
    };

    if (!ready) return <Loading />
    if (error) return alert('Something is wrong.')
    if (!isLoggedIn) {
        return (
            <div className="center" style={{ maxWidth: '30rem' }}>
                <Header />
                <div style={{ padding: '40px' }}>
                    {buttonLogin()}
                </div>
            </div>
        )
    }

    if (isLoading) return <Loading />
    
    return (
        <div className="center" style={{ maxWidth: '48rem' }}>
            <Header />
            <div style={{ padding: '32px' }}>
                <p className="p-2" style={{ fontWeight: 400, fontSize: '18px' }}>ยืนยันการขอเพิ่มวงเงิน</p>

                <img className='mt-3 mb-5' src={status ? Success : Fail} style={{ width: '100px' }} alt="" />

                {status ? (
                    <>
                        <div style={{ textAlign: 'left', fontWeight: 400, fontSize: '14px', marginBottom: '2rem' }}>
                            <p>
                                ยินดีด้วยค่ะ หากท่านสนใจเพิ่มวงเงิน สามารถกดปุ่ม "ตกลง" เพื่อดำเนินการต่อพร้อมอัปโหลดเอกสารเพิ่มเติมดังนี้
                            </p>
                            <ul style={{ color: "#fff" }}>
                                <li>รายการเดินบัญชีธนาคาร (Statement) ย้อนหลัง 3 เดือน</li>
                                <li>สลิปเงินเดือน 1 เดือนย้อนหลัง</li>
                                <li>เอกสารอื่นๆ (ถ้ามี)</li>
                            </ul>
                            <p>
                                เจ้าหน้าที่จะติดต่อหาท่านหลังอัปโหลดเอกสารครบถ้วน ภานใน 1-3 วันทำการ
                            </p>
                        </div>

                        <Row>
                            <Button className="btn btn-secondary mt-4" onClick={() => handleClickApproval()}>ตกลง</Button>
                            <Button className="btn btn-danger mt-4" onClick={() => window.history.back()}>ยกเลิก</Button>
                        </Row>
                    </>
                ) : (
                    <>
                        <div style={{ textAlign: 'center', fontWeight: 400, fontSize: '14px', marginBottom: '2rem' }}>
                            <p>
                                ขอแสดงความเสียใจด้วยนะคะ ท่านไม่ผ่านเกณฑ์การเพิ่มวงเงินในรอบเดือนนี้นะคะ
                                ท่านสามารถลองสมัครได้ใหม่อีกครั้งในภายหลังค่ะ
                            </p>
                        </div>

                        <Row>
                            <Button className="btn btn-danger mt-4" onClick={() => window.history.back()}>ปิด</Button>
                        </Row>
                    </>
                )}

            </div>
        </div>
    );
}
