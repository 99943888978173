import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Row, Button } from 'react-bootstrap';
import * as userHttpServices from '../services/digital-app-services';
import Cookies from 'js-cookie';
import Loading from "../components/loading";
import Header from './../components/header/duo_logo'

const ActionType = {
    Online: 1,
    ChangeBank: 2,
};

function Home() {
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState('');

    useEffect(() => {
        const lineAccessToken = Cookies.get('lineAccessToken');
        console.log('lineAccessToken', lineAccessToken)

        const handleLineLogin = async () => {
            try {
                const res = await userHttpServices.lineLogin(lineAccessToken);
                console.log('res', res)
                localStorage.setItem("lineLogin", JSON.stringify(res));
                localStorage.setItem("brand", res.data?.detail?.brand);
                localStorage.setItem("product", res.data?.detail?.product);
                localStorage.setItem("phone", res?.data?.phone_number);
                
                setData(res);
                setIsLoading(false);

            } catch (error) {
                console.error("Error:", error);
                alert('[HRL36] Error has occurred:', lineAccessToken);
            }
        };

        handleLineLogin();
    }, []);

    const handleClick = (type) => {
        switch (type) {
            case ActionType.Online:
                if (data.data.data === null) {
                    navigate("/otp");
                } else {
                    navigate("/upload");
                }
                break;
            case ActionType.ChangeBank:
                console.log('เปลี่ยนธนาคาร');
                break;
            // Handle other actions
            default:
                break;
        }
    };

    if (isLoading) return <Loading />

    return (
        <div className="center" style={{ maxWidth: '30rem' }}>
            <Header />
            <div style={{ padding: '2px' }}>
                <p className="p-2" style={{ fontWeight: 400, fontSize: 18 }}>กรุณาเลือก</p>
                <Row>
                    <Col xs="12" style={{ padding: 10 }}>
                        <Button variant="select-md" onClick={() => handleClick(1)}>ส่งเอกสารออนไลน์</Button>
                    </Col>
                    <Col xs="12" style={{ padding: 10 }}>
                        <Button variant="select-md" onClick={() => handleClick(0)}>ส่งเอกสารทางไปรษณีย์</Button>
                    </Col>
                    <Col xs="12" style={{ padding: 10 }}>
                        <Button variant="select-md" onClick={() => handleClick(0)} >ส่งผ่าน Messanger</Button>
                    </Col>
                    <Col xs="12" style={{ padding: 10 }}>
                        <Button variant="select-md" onClick={() => handleClick(1)} >ลูกค้าเก่าขอเพิ่มวงเงิน</Button>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default Home;

