import Fail from './../assets/images/2.png'
import Header from './../components/header/normal_logo'

export default function PageNotFound() {
    return (
        <div className="center" style={{ maxWidth: '30rem' }}>
            <Header/>
            <div style={{ padding: '40px' }}>
                <img className='mb-4' src={Fail} style={{ width: '80px' }} alt="fail" />

                <h1>Server Error!</h1>
                <p>ระบบมีปัญหากรุณาติดต่อเจ้าหน้าที่<br />ขออภัยในความไม่สะดวกครับ/ค่ะ</p>

                <br />
                <br />
                <br />

                <a href="/" style={{ color: '#fff' }}>Go back to homepage</a>

            </div>
        </div>

    );
}