import React from 'react'
import { Button, Row } from 'react-bootstrap';

const Page3 = ({ type, images, handleBack }) => {

    const styles = {
        wrapper: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "5px"
        },
        imageWrapper: {
            border: "1px solid rgba(0,0,0,0.15)",
            borderRadius: "3px",
            boxShadow: "0 2px 5px 0 rgba(0,0,0,0.25)",
            padding: "0"
        }
    };

    const menu = (type) => {
        if (type === 'id_card_no') {
            return 'สำเนาบัตรประชาชน'
        } else if (type === 'statement') {
            return 'รายการเดินบัญชีธนาคาร'
        } else if (type === 'salary') {
            return 'สลิป/หนังสือรับรองเงินเดือน'
        } else if (type === 'house_regis') {
            return 'สำเนาทะเบียนบ้าน'
        } else if (type === 'book_bank') {
            return 'หน้าสมุดบัญชีธนาคาร'
        } else if (type === 'auth') {
            return 'รูปถ่ายคู่บัตรประชาชน'
        } else if (type === 'other') {
            return 'เอกสารอื่นๆ'
        }
    }

    return (
        <div className='center' style={{ maxWidth: '30rem' }}>
            <div style={{ padding: '45px' }}>
                <div className="header-subject center" style={{ borderRadius: '30px', width: '220px' }}>
                    <p className="p-2" style={{ fontWeight: 400, fontSize: 18}}>
                        เลือกไฟล์เอกสาร
                    </p>
                </div>

                <p style={{ fontSize: '18px', fontWeight: 400, textAlign: 'center' }}>
                    {menu(type)}
                </p>

                <div id="image-convas-row">
                    <div style={styles.wrapper}>
                        {images.map((image, idx) => (
                            <div key={idx} style={styles.imageWrapper}>
                                <img
                                    id="image-generated"
                                    src={image}
                                    alt="pdfImage"
                                    style={{
                                        width: "100%",
                                        height: "100%",
                                        margin: "0",
                                        border: "none"
                                    }}
                                />
                            </div>
                        ))}
                    </div>
                </div>

                <div className='mt-5'>
                    <Row >
                        <Button id='submitUpload' variant="secondary" style={{}} onClick={handleBack}>เรียบร้อย</Button>
                    </Row>
                </div>

            </div>
        </div>
    )
}

export default Page3
