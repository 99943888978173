
import Success from './../assets/images/1.png'
import Header from './../components/header/normal_logo'

export default function PageNotFound() {
    return (
        <div className="center" style={{ maxWidth: '30rem' }}>
            <Header/>
            <div style={{ padding: '40px' }}>
                <img className='mb-4' src={Success} style={{ width: '80px' }} alt="success" />

                <p style={{ fontSize: '22px' }}>กรุณาดำเนินการต่อในช่องทาง<br />ของสถาบันการเงิน</p>
                <p style={{ fontSize: '14px' }}>คุณสามารถดำเนินการทางการเงินและทำรายการทางธนาคารตามที่คุณต้องการ โปรดระมัดระวังและอย่าให้ข้อมูลส่วนตัวหรือข้อมูลการเข้าสู่ระบบถูกเปิดเผยแก่บุคคลที่ไม่พึงประสงค์ อย่างเช่น รหัสผ่านหรือข้อมูลบัตรเครดิต เพื่อรักษาความปลอดภัยของบัญชีของคุณ</p>

                <br />
                <br />
                <br />

                <a href="/" style={{ color: '#fff' }}>Go back to homepage</a>

            </div>
        </div>

    );
}
