import React, { useState } from 'react'
import { HiArrowLeft } from "react-icons/hi";

import Stepper from '../components/prescreen/stepper';
import PageFirst from '../components/prescreen/page1'
import PageSecond from '../components/prescreen/page2'

const Prescreen = () => {
    const [step, setStep] = useState(1)
    const [values, setValues] = useState({
        document: null,
        ncb_status: null
    })

    const renderPage = (step) => {
        switch (step) {
            case 1:
                return <PageFirst setStep={setStep} values={values} setValues={setValues} />
            case 2:
                return <PageSecond setStep={setStep} values={values} setValues={setValues} />
            default:
            // code block
        }
    }

    const handleBack = () => {
        setStep((prev) => {
            console.log('prev', prev)
            if (prev > 1) {
                return prev - 1;
            } else {
                return 1;
            }
        });
    }

    return (
        <div>
            <div className='center' style={{
                backgroundColor: '#fff',
                maxWidth: '30rem',
                height: '100%',
                background: 'linear-gradient(to bottom, #fff 85%, rgba(36, 62, 123, 1))',
                boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px'
            }}>
                <div className='sticky-div' style={{ backgroundColor: 'rgba(36, 62, 123, 1)', height: '68px', display: 'flex' }}>
                    <div style={{ textAlign: 'left', position: "absolute", top: '16px', paddingLeft: '1rem' }}>
                        <HiArrowLeft fontSize='34' color='rgba(240, 90, 39, 1)' onClick={() => handleBack()} />
                    </div>
                    <div className='content-section center' style={{ color: 'rgba(255, 255, 255, 1)' }}>
                        คุณสมบัติ
                    </div>
                </div>

                <Stepper step={step} />

                {renderPage(step)}
            </div>
        </div>
    )
}

export default Prescreen
