/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */

import { useEffect, useState, useRef } from "react";
// import { Typeahead } from 'react-bootstrap-typeahead';

import { Col, Row, Carousel, Button, ProgressBar, Modal, Form, Nav, Navbar, Container, NavItem, NavLink } from 'react-bootstrap';
import { Link, useNavigate } from "react-router-dom";
// import { useSelector, useDispatch } from 'react-redux';
// import { useLiff } from 'react-liff';
// import InputGroup from 'react-bootstrap/InputGroup';
import { padLeadingZeros } from '../../utils';
// import provincesData from '../datas/provinces.json';

// import LogoImg from '../assets/images/logo_msmoneyspeed.png'

// import * as userHttpServices from '../services/digital-app-services';
// import { Formik } from 'formik';
// import * as Yup from "yup";
// import CloseButton from 'react-bootstrap/CloseButton';

const thaiMonthName = ["มกราคม", "กุมภาพันธ์", "มีนาคม", "เมษายน", "พฤษภาคม", "มิถุนายน", "กรกฎาคม", "สิงหาคม", "กันยายน", "ตุลาคม", "พฤศจิกายน", "ธันวาคม"]

function Home() {
    let navigate = useNavigate();

    // orientation
    const [orientation, setOrientation] = useState(window.orientation);
    useEffect(() => {
        const handleOrientationChange = () => {
            const newOrientation = window.orientation || window.screen.orientation.angle;
            setOrientation(newOrientation);
        };

        window.addEventListener("orientationchange", handleOrientationChange);
        return () => {
            window.removeEventListener("orientationchange", handleOrientationChange);
        };
    }, []);

    useEffect(() => {
    }, []);

    const handleRegister = () => {
        navigate('/direct_debit/consent')
    }

    if (false) {
        return
    } else {
        return (
            <>
                <div className="mobile">
                    {/* add border space */}
                    <div className="p-3" >
                        <div className="header" style={{ backgroundColor: "#F15A29", borderRadius: '20px', width: '200px' }}>
                            <p className="p-2" style={{ fontWeight: 300, fontSize: 18, textAlign: 'center' }}>
                                สมัครสมาชิก
                            </p>
                        </div>
                        <p style={{ fontWeight: 300, fontSize: 16 }}>ข้อมูลสมาชิก</p>
                        <div>
                            <Row>
                                <Col xs="12" style={{ padding: 5 }}>
                                    <Form.Group className="mb-2">
                                        <Form.Control
                                            size="md"
                                            placeholder="ชื่อ"
                                            type="text"
                                            name="address"
                                        // value={(values.address)}
                                        // onChange={handleChange}
                                        // isInvalid={!!errors.address}
                                        />
                                        {/* <Form.Control.Feedback type="invalid">
                                            {errors.address}
                                        </Form.Control.Feedback> */}
                                    </Form.Group>
                                </Col>

                                <Col xs="12" style={{ padding: 5 }}>
                                    <Form.Group className="mb-2">
                                        <Form.Control
                                            size="md"
                                            placeholder="นามสกุล"
                                            type="text"
                                            name="address"
                                        // value={(values.address)}
                                        // onChange={handleChange}
                                        // isInvalid={!!errors.address}
                                        />
                                        {/* <Form.Control.Feedback type="invalid">
                                            {errors.address}
                                        </Form.Control.Feedback> */}
                                    </Form.Group>
                                </Col>

                                <Col xs="12" style={{ padding: 5 }}>
                                    <Form.Group className="mb-2">
                                        <Form.Control
                                            size="md"
                                            placeholder="เลขบัตรประชาชน"
                                            type="text"
                                            name="address"
                                        // value={(values.address)}
                                        // onChange={handleChange}
                                        // isInvalid={!!errors.address}
                                        />
                                        {/* <Form.Control.Feedback type="invalid">
                                            {errors.address}
                                        </Form.Control.Feedback> */}
                                    </Form.Group>
                                </Col>

                                <Col xs="4" style={{ padding: 5 }}>
                                    <Form.Group className="mb-2">
                                        <Form.Select
                                            size="md"
                                            placeholder="วัน"
                                            type="text"
                                            name="birthDate"
                                            value={''}
                                            // onChange={handleChange}
                                            // isInvalid={!!errors.birthDate}
                                        >
                                            <option value='' defaultValue disabled> วัน </option>
                                            {
                                                Array.from(Array(31), (e, i) => {
                                                    return <option key={i} value={i + 1}>{padLeadingZeros(i + 1, 2)}</option>
                                                })
                                            }
                                        </Form.Select>
                                        {/* <Form.Control.Feedback type="invalid">
                                            {errors.birthDate}
                                        </Form.Control.Feedback> */}
                                    </Form.Group>
                                </Col>
                                <Col xs="4" style={{ padding: 5 }}>
                                    <Form.Group className="mb-2">
                                        <Form.Select
                                            size="md"
                                            placeholder="เดือน"
                                            type="text"
                                            name="birthMonth"
                                            value={''}
                                            // onChange={handleChange}
                                            // isInvalid={!!errors.birthMonth}
                                        >
                                            <option value='' defaultValue disabled> เดือน </option>
                                            {
                                                Array.from(Array(12), (e, i) => {
                                                    return <option key={i} value={i + 1}>{thaiMonthName[i]} ({padLeadingZeros(i + 1, 2)})</option>
                                                })
                                            }
                                        </Form.Select>
                                        {/* <Form.Control.Feedback type="invalid">
                                            {errors.birthMonth}
                                        </Form.Control.Feedback> */}
                                    </Form.Group>
                                </Col>
                                <Col xs="4" style={{ padding: 5 }}>
                                    <Form.Group className="mb-2">
                                        <Form.Select
                                            size="md"
                                            placeholder="ปีเกิด"
                                            type="text"
                                            name="birthYear"
                                            value={''}
                                            // onChange={handleChange}
                                            // isInvalid={!!errors.birthYear}
                                        >
                                            <option value='' defaultValue disabled> ปีเกิด </option>
                                            {
                                                Array.from(Array(66), (e, i) => {
                                                    return <option key={i} value={2550 - 543 - i}>{2550 - i} ({2550 - 543 - i})</option>
                                                })
                                            }
                                        </Form.Select>
                                        {/* <Form.Control.Feedback type="invalid">
                                            {errors.birthYear}
                                        </Form.Control.Feedback> */}
                                    </Form.Group>
                                </Col>
                            </Row>
                        </div>

                        <div className={orientation === 90 ? 'm-5' : 'footer'}>
                            <Row >
                                <Button variant="secondary" onClick={() => handleRegister()}>ลงทะเบียน</Button>
                            </Row>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default Home;