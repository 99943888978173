import React, { useState } from 'react'
import PhoneFill from '../components/otp/page1'
import ValidateOTP from '../components/otp/page2'

const CallOTP = () => {
    const [phone, setPhone] = useState('');
    const [page, setPage] = useState(1)

    const renderPage = (page) => {
        switch (page) {
            case 1:
                return <PhoneFill
                    phone={phone}
                    setPhone={setPhone}
                    setPage={setPage}
                />
            case 2:
                return <ValidateOTP
                    phone={phone}
                />
            default:
            // code block
        }
    }

    return (
        <div>
            <div className='center' style={{ maxWidth: '30rem' }}>
                {renderPage(page)}
            </div>
        </div>
    )
}

export default CallOTP
