import React from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

import { LiffProvider } from 'react-liff';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App';
import Home from './pages/home';
import OTP from './pages/otp';
import Form from './pages/register/form';
import Signature from './pages/register/signature';
import Upload from './pages/upload';
import Summary from './pages/summary';
import Success from './pages/new_upload_success';
import Unsuccess from './pages/new_upload_unsuccess';
import Register from './pages/direct-debit/register';
import Consent from './pages/direct-debit/consent';
import End from './pages/direct-debit/end';
import PageNotFound from './pages/404';
import ServerError from './pages/400';
import BadGateway from './pages/502';
import Accepted from './pages/202';
import BankRegister from './pages/direct-debit/bank-register';
import Gateway from './pages/gateway';
import MicroSite from './pages/prescreen';
import RegisterLine from './pages/register_line';
import RegisterLineSuccess from './pages/register_line_success';
import { changeBackgroundConfig } from './utils';

// Register
import ContractDraft from './pages/register/contract-draft';

// Refinance
import Refinance from './pages/refinance';

// Direct Debit
import ConsentDirectDebit from './pages/consent-direct-debit'

// Check if authentication is enabled and lineAccessToken is empty
// if (process.env.REACT_APP_AUTHENTICATION === "true" && lineAccessToken === "") {
//     navigate("/");
//     return;
// }

changeBackgroundConfig()

//1661354297-LA26EQNY DEV-CW
//1660820444-Xy4wGKeW PROD
//2000989233-YQVkp074 Staging
//2001055286-xzBzKl9n MS-Staging
//2001055286-yNBmJ6bR MS-Staging --New Register
//1657257882-kN4LvWm5 MSCW
//1657257882-OknNAm6z MSCW --New Register

const MASTER_LIFFID = '1657257882-kN4LvWm5';
const liffId2 = '1657257882-OknNAm6z'; //MS --New Register
const liffRefinance = '1657257882-52GEQLPJ';

let currentLiffId = MASTER_LIFFID;

switch (window.location.pathname) {
  case '/line/register':
    currentLiffId = liffId2;
    break;
  case '/refinance':
    currentLiffId = liffRefinance;
    break;
  default:
    break;
}

ReactDOM.render(
  <LiffProvider liffId={currentLiffId} stubEnabled={false}>
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<App />} />
          <Route path="/home" element={<Home />} />
          <Route path="/otp" element={<OTP />} />
          <Route path="/form" element={<Form />} />
          <Route path="/signature" element={<Signature />} />
          <Route path="/upload" element={<Upload />} />
          <Route path="/summary" element={<Summary />} />
          <Route path="/success" element={<Success />} />
          <Route path="/unsuccess" element={<Unsuccess />} />
          <Route path="/prescreen" element={<MicroSite />} />
          <Route path="/line/register" element={<RegisterLine />} />
          <Route path="/line/register/success" element={<RegisterLineSuccess />} />

          {/* Register */}
          <Route path="/contract/draft" element={<ContractDraft />} />

          {/* Direct Debit */}
          <Route path="/direct_debit/register" element={<Register />} />
          <Route path="/direct_debit/consent" element={<Consent />} />
          <Route path="/direct_debit/end" element={<End />} />
          <Route path="/direct_debit/bank-register" element={<BankRegister />} />
          <Route path="/gateway/:bank/:id" element={<Gateway />} />
          <Route path="/consent-direct-debit/:bank/:id" element={<ConsentDirectDebit />} />

          {/* Refinance */}
          <Route path="/refinance" element={<Refinance />} />

          {/* Other */}
          <Route path="502" element={<BadGateway />} />
          <Route path="400" element={<ServerError />} />
          <Route path="202" element={<Accepted />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </BrowserRouter>
    </Provider>
  </LiffProvider>,
  document.getElementById('root')
)

