import React from 'react'
import { Modal } from 'react-bootstrap'
import CloseButton from 'react-bootstrap/CloseButton';
import Header from './../../components/header/small_logo'

const Model = ({ show, handleClose }) => {
    return (
        <div>
            <Modal show={show} onHide={handleClose}>
                <Modal.Body>
                    <div className="right">
                        <CloseButton style={{ backgroundColor: '#fff', padding: '10px', marginBottom: '20px', fontSize: '12px' }} onClick={handleClose} />
                    </div>
                    <p className='mb-3' style={{ textAlign: 'center', fontSize: '16px', textDecoration: 'underline' }}>การยินยอมในการเปิดเผยข้อมูล</p>
                    <Header />
                    <div style={{ marginBottom: '3rem', fontSize: '12px' }}>
                        <p>
                            &emsp;&emsp;ตกลงยินยอมให้ บริษัท ข้อมูลเครดิตแห่งชาติ จำกัด เปิดเผยหรือให้ข้อมูลของข้าพเจ้าแก่ บริษัท เอ็ม เอส ซี ดับเบิยู จำกัด ซึ่งเป็นสมาชิกหรือผู้ใช้บริการของบริษัท เพื่อประโยชน์ในการวิเคราะห์สินเชื่อการออกบัตรเครดิต ตามคำขอสินเชื่อ / ขอออกบัตรเครดิตของข้าพเจ้าที่ให้ไว้กับธนาคาร / บริษัทดังกล่าวข้างต้น รวมทั้งเพื่อประโยชน์ในการทบทวนสินเชื่อต่ออายุสัญญาสินเชื่อ / บัตรเครคิต การบริหารและป้องกันความเสี่ยงตามข้อกำหนดของธนาคารแห่งประเทศไทย และให้ถือว่าคู่ฉบับ และบรรดาสำเนาภาพถ่ายข้อมูลอิเล็กทรอนิกส์หรือโทรสารที่ทำสำเนาขึ้นจากหนังสือให้ความยินยอมฉบับนี้โดยการถ่ายสำเนาถ่ายภาพ หรือบันทึกไว้ไม่ว่าในรูปแบบใดๆ เป็นหลักฐานในการให้ความยินยอมของข้าพเจ้าเช่นเดียวกัน<br />
                            <br />
                            หมายเหตุ: ข้อมูลที่บริษัทเปิดเผยให้แก่สมาชิกหรือผู้ใช้บริการป็นองค์ประกอบหนึ่งในการพิจารณาสินเชื่อของสถาบันการเงินแต่การเปิดเผยข้อมูลดังกล่าวเป็นสิทธิของเจ้าของข้อมูลที่จะให้ความยินยอมหรือไม่ก็ได้
                        </p>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default Model