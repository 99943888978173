import React from 'react'
import { Modal } from 'react-bootstrap'
import CloseButton from 'react-bootstrap/CloseButton';
import Header from './../../components/header/small_logo'

const Model = ({ show, handleClose }) => {
    return (
        <div>
            <Modal show={show} onHide={handleClose}>
                <Modal.Body>
                    <div className="right">
                        <CloseButton style={{ backgroundColor: '#fff', padding: '10px', marginBottom: '20px', fontSize: '12px' }} onClick={handleClose} />
                    </div>
                    <p className='mb-3' style={{textAlign: 'center', fontSize: '16px', textDecoration: 'underline' }}>เงื่อนไขค่าธรรมเนียมบริการชำระสินเชื่ออัตโนมัติ</p>
                    <Header />
                    <div style={{ textAlign: 'left',fontSize: '12px', marginBottom: '5rem' }}>
                        <p>
                            &emsp;&emsp;กรณีที่ลูกค้าได้รับการอนุมัติสินเชื่อลูกค้าตกลงจะชำระสินเชื่อผ่านบริการชำระสินเชื่ออัตโนมัติจนกว่าจะชำระสินเชื่อเสร็จสิ้น อัตราค่าธรรมเนียมการใช้บริการดังกล่าวจะชำระให้กับบริษัท เซ็นดิท เทค จำกัด ซึ่งเป็นบุคคลที่ 3 (service provider) ที่ได้รับการรับรองในฐานะผู้ให้บริการดังกล่าวโดยมีการหักค่างวดอัตโนมัติกับธนาคาร ดังนี้
                        </p>
                        <ul style={{ color: "#fff" }}>
                            <li>ธนาคาร กสิกรไทย ค่าธรรมเนียมการตัดเงิน จำนวน 10.70 บาท/ต่อรายการ</li>
                            <li>ธนาคาร ไทยพาณิชย์, กรุงศรีอยุธยา, กรุงเทพ, กรุงไทย ค่าธรรมเนียมการตัดเงิน  จำนวน  7.49 บาท/ต่อรายการ</li>
                        </ul>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default Model