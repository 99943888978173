import React from 'react'
import { Form } from 'react-bootstrap'
import Swal from 'sweetalert2';

const PageFirst = ({ setStep, values, setValues }) => {

    const handleRadioChange = (e) => {
        const { name, value } = e.target
        setValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
    };

    const handleSubmit = () => {
        if (values.document) {
            localStorage.setItem('document', values.document)

            if (values.document === 'เข้าเกณฑ์ (มีเอกสารครบถ้วน)') handleSuccess()
            else handleError()

        } else {
            alert('กรุณาเลือกเกณฑ์เอกสาร')
        }
    }

    const handleError = () => {
        Swal.fire({
            icon: 'error',
            html: 'ขออภัยท่านไม่เข้าเกณฑ์ของบริษัท<br/>ขอบคุณที่สนใจสินเชื่อของ<br/>Money Speed ค่ะ',
            showConfirmButton: true,
            confirmButtonText: 'ตกลง',
            backdrop: false,
            customClass: {
                container: 'custom-swal-container',
                htmlContainer: 'custom-swal-html',
                confirmButton: 'custom-confirm-button',
            },
            willOpen: () => {
                const modal = Swal.getPopup();
                modal.style.borderRadius = '10px';
            },
        }).then(() => {
            window.location.href = '/'
        })
    }

    const handleSuccess = () => {
        Swal.fire({
            icon: 'success',
            html: 'คุณมีรายได้รวม 12,000 บาท ขึ้นไป<br>และต้องการสมัครสินเชื่อ<br>------------------<br>กรุณาทำแบบสอบถามให้ครบถ้วน<br>และเจ้าหน้าที่จะติดต่อกลับอย่างเร็วที่สุด',
            showConfirmButton: true,
            confirmButtonText: 'ตกลง',
            backdrop: false,
            customClass: {
                container: 'custom-swal-container',
                htmlContainer: 'custom-swal-html-green',
                confirmButton: 'custom-confirm-button',
            },
            willOpen: () => {
                const modal = Swal.getPopup();
                modal.style.borderRadius = '10px';
            },
        }).then(() => {
            setStep(prev => prev + 1)
        })
    }

    return (
        <div>
            <div style={{ backgroundColor: 'rgba(240, 90, 39, 0.1)', height: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div className='content-header'>
                    คุณสมบัติและเอกสารสำหรับการขอสินเชื่อ
                </div>
            </div>

            <div style={{ padding: '30px' }}>
                <div style={{ textAlign: 'left' }}>
                    <div className='content-header mb-1'>
                        คุณสมบัติผู้ขอสินเชื่อ
                    </div>
                    <div className='content'>
                        1.อายุ 20 - 57 ปี<br />
                        2.รายได้รวม 12,000 บาท ขึ้นไป<br />
                        3.มีสลิปเงินเดือน หรือ หนังสือรับรองเงินเดือน<br />
                        4.สถานะปัจจุบันไม่ค้างจ่าย<br />
                        (หากเคยเสียแต่ปัจจุบันแก้ไขเป็นปกติก็สามารถขอสินเชื่อได้)<br />
                    </div>
                    <br />

                    <div className='content-header mb-1'>
                        เอกสารที่ต้องใช้ในการสมัครสินเชื่อ
                    </div>
                    <div className='content'>
                        1.สำเนาบัตรประจำตัวประชาชน 1 ฉบับ  <br />
                        พร้อมรับรองสำเนาถูกต้อง <br />
                        2.สำเนาทะเบียนบ้าน 1 ฉบับ พร้อมรับรองสำเนาถูกต้อง <br />
                        3.หน้าสมุดบัญชีธนาคาร 1 ฉบับ <br />
                        4.สลิปเงินเดือนย้อนหลัง 6 เดือนหรือหนังสือรับรองเงินเดือน <br />
                        5.รายการเดินบัญชีเงินเดือน (สเตทเม้นท์ธนาคาร) <br />
                        ย้อนหลัง 6 เดือน <br />
                        6.(กรณีถ้ามี) บัญชีธนาคารที่ใช้ในชีวิตประจำวันโปรดแสดง เพิ่มเติม 6 เดือน<br />
                    </div>
                </div>
                <br />

                <div className='content-header m-1' style={{color:'rgb(225 29 72)'}}>
                    {/* (ท่านสามารถอัปโหลดเอกสารย้อนหลังภายใน 7 วัน <br />หลังกรอกแบบฟอร์มเสร็จสิ้น) */}
                    ท่านต้องอัปโหลดเอกสารให้แล้วเสร็จภายใน 7 วัน<br />
                    หากไม่แล้วเสร็จท่านต้องทำการสมัครสินเชื่อใหม่
                </div>

                <Form style={{ margin: '2.5rem 0' }}>
                    <Form.Check
                        inline
                        label={<div className='content-2' style={{ color: 'rgba(36, 62, 123, 1)' }}>เข้าเกณฑ์ <br />(เอกสารครบ)</div>}
                        name="document"
                        type="radio"
                        id={`inline-radio-1`}
                        className="custom-radio-dot"
                        defaultChecked={values.document === 'เข้าเกณฑ์ (มีเอกสารครบถ้วน)'}
                        value={'เข้าเกณฑ์ (มีเอกสารครบถ้วน)'}
                        onChange={(e) => handleRadioChange(e)}
                    />
                    <Form.Check
                        inline
                        label={<div className='content-2' style={{ color: 'rgba(36, 62, 123, 1)' }}>ไม่เข้าเกณฑ์ <br />(ไม่มี)</div>}
                        name="document"
                        type="radio"
                        id={`inline-radio-2`}
                        className="custom-radio-dot"
                        defaultChecked={values.document === 'ไม่เข้าเกณฑ์ (ไม่มีเอกสารประกอบตามเงื่อนไข)'}
                        value={'ไม่เข้าเกณฑ์ (ไม่มีเอกสารประกอบตามเงื่อนไข)'}
                        onChange={(e) => handleRadioChange(e)}
                    />
                </Form>

                <button className="custom-button" onClick={() => handleSubmit()}>ตกลง</button>
            </div>
        </div>
    )
}

export default PageFirst
