import React, { useEffect } from 'react'
import { Col, Row, Button, Form } from 'react-bootstrap';
// import { validCompPhone } from '../../../src/assets/regex';
import Address from './address';
import Career from './career';

const FormEditor3 = ({ isError, setIsError, values, setValues, setActiveStep, validateForm }) => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        if (values.radioGroupAddress2 === "1") {
            setValues((prevState) => ({
                ...prevState,
                compAddress: values.address,
                compProvince: values.province,
                compCounty: values.county,
                compDistrict: values.district,
                compZipcode: values.zipcode,
            }));
        } else if (values.radioGroupAddress2 === "2") {
            setValues((prevState) => ({
                ...prevState,
                compAddress: values.currentAddress,
                compProvince: values.currentProvince,
                compCounty: values.currentCounty,
                compDistrict: values.currentDistrict,
                compZipcode: values.currentZipcode,
            }));
        }
    }, [setValues,
        values.address,
        values.county,
        values.currentAddress,
        values.currentCounty,
        values.currentDistrict,
        values.currentProvince,
        values.currentZipcode,
        values.district,
        values.province,
        values.radioGroupAddress2,
        values.zipcode
    ])

    const handleSubmit = (event) => {
        event.preventDefault();

        const hasErrorValue = validateForm()
        if (hasErrorValue) return

        setActiveStep((prevActiveStep) => ({ current: prevActiveStep.current + 1, top: prevActiveStep.top + 1 }));
    };

    const handleChange = (event) => {
        const { name, value } = event.target
        const formattedValue = value.replace(/,/g, ''); // ลบจุลภาคออกก่อนเก็บใน state
        setValues((prevState) => ({
            ...prevState,
            [name]: formattedValue,
        }));
    }

    const handleChangeNumber = (event) => {
        const { name, value } = event.target;
        // ลบจุลภาคและสิ่งที่ไม่ใช่ตัวเลขออก
        const formattedValue = value.replace(/,/g, '').replace(/\D/g, '');
    
        setValues((prevState) => ({
            ...prevState,
            [name]: formattedValue,
        }));
    };

    function formatNumber(value) {
        if (!value) return "";
        value =  value.replace(/\D/g, ''); // ใช้ regex เพื่อเก็บเฉพาะตัวเลข
        return value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } 

    function formatNumberAllowText(value) {
        if (!value) return "";
        return value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    return (
        <div>
            <Form onSubmit={handleSubmit}>
                <Form.Group>
                    <Row>
                        <Col xs="12" className="d-flex align-items-left">
                            <Form.Check className='custom-radio-dot'>
                                <Form.Check.Input
                                    id="radioGroupAddress2-0"
                                    type="radio"
                                    name="radioGroupAddress2"
                                    value="0"
                                    checked={values.radioGroupAddress2 === '0'}
                                    onChange={() =>
                                        setValues((prevState) => ({
                                            ...prevState,
                                            radioGroupAddress2: "0",
                                            compAddress: "",
                                            compProvince: "",
                                            compCounty: "",
                                            compDistrict: "",
                                            compZipcode: "",
                                        }))
                                    }
                                    style={{
                                        width: '20px',
                                        height: '20px',
                                        borderRadius: '50px'
                                    }} />
                                <Form.Check.Label style={{
                                    marginLeft: '5px',
                                }}>
                                    <p style={{ textDecoration: 'none', fontWeight: 300 }}>
                                        กำหนดเอง
                                    </p>
                                </Form.Check.Label>
                            </Form.Check>
                        </Col>
                        <Col xs="12" className="d-flex align-items-left">
                            <Form.Check className='custom-radio-dot'>
                                <Form.Check.Input
                                    id="radioGroupAddress2-1"
                                    type="radio"
                                    name="radioGroupAddress2"
                                    value="1"
                                    checked={values.radioGroupAddress2 === '1'}
                                    onChange={() =>
                                        setValues((prevState) => ({
                                            ...prevState,
                                            radioGroupAddress2: '1',
                                            compAddress: values.address,
                                            compProvince: values.province,
                                            compCounty: values.county,
                                            compDistrict: values.district,
                                            compZipcode: values.zipcode,
                                        }))
                                    }
                                    style={{
                                        width: '20px',
                                        height: '20px',
                                        borderRadius: '50px'
                                    }} />
                                <Form.Check.Label style={{
                                    marginLeft: '5px',
                                }}>
                                    <p style={{ textDecoration: 'none', fontWeight: 300 }}>
                                        ใช้ที่อยู่บัตรประชาชน
                                    </p>
                                </Form.Check.Label>
                            </Form.Check>
                        </Col>
                        <Col xs="12" className="d-flex align-items-left">
                            <Form.Check className='custom-radio-dot'>
                                <Form.Check.Input
                                    id="radioGroupAddress2-2"
                                    type="radio"
                                    name="radioGroupAddress2"
                                    value="2"
                                    checked={values.radioGroupAddress2 === '2'}
                                    onChange={() =>
                                        setValues((prevState) => ({
                                            ...prevState,
                                            radioGroupAddress2: '2',
                                            compAddress: values.currentAddress,
                                            compProvince: values.currentProvince,
                                            compCounty: values.currentCounty,
                                            compDistrict: values.currentDistrict,
                                            compZipcode: values.currentZipcode,
                                        }))
                                    }
                                    style={{
                                        width: '20px',
                                        height: '20px',
                                        borderRadius: '50px'
                                    }} />
                                <Form.Check.Label style={{
                                    marginLeft: '5px',
                                }}>
                                    <p style={{ textDecoration: 'none', fontWeight: 300 }}>
                                        ใช้ที่อยู่ปัจจุบัน
                                    </p>
                                </Form.Check.Label>
                            </Form.Check>
                        </Col>
                    </Row>

                    <br />
                    <div style={{ textAlign: 'left' }}>
                        <p style={{ fontWeight: 300, fontSize: 16 }}>ที่อยู่ที่ทำงาน</p>
                    </div>
                    <Row>
                        <Col xs="12" style={{ padding: 5 }}>
                            <Form.Group className="mb-2">
                                <Form.Control
                                    size="md"
                                    placeholder="ประเภทกิจการบริษัท/โรงงาน"
                                    type="text"
                                    name="compType"
                                    id="compType"
                                    value={values.compType}
                                    onChange={handleChange}
                                    isInvalid={isError.err?.compType}
                                    autoComplete="off" // แก้ไขปัญหา autocomplete
                                />
                                <Form.Control.Feedback type="invalid">
                                    <div className="d-flex">
                                        โปรดกรอกประเภทกิจการบริษัท/โรงงาน
                                    </div>
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col xs="12" style={{ padding: 5 }}>
                            <Form.Group className="mb-2">
                                <Form.Control
                                    size="md"
                                    placeholder="ชื่อบริษัท/โรงงาน"
                                    type="text"
                                    name="compName"
                                    id="compName"
                                    value={values.compName}
                                    onChange={handleChange}
                                    isInvalid={isError.err?.compName}
                                    autoComplete="off" // แก้ไขปัญหา autocomplete
                                />
                                <Form.Control.Feedback type="invalid">
                                    <div className="d-flex">
                                        โปรดกรอกชื่อบริษัท/โรงงาน
                                    </div>
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Address
                            values={{
                                address: values.compAddress,
                                province: values.compProvince,
                                county: values.compCounty,
                                district: values.compDistrict,
                                zipcode: values.compZipcode
                            }}
                            name={{
                                address: 'compAddress',
                                province: 'compProvince',
                                county: 'compCounty',
                                district: 'compDistrict',
                                zipcode: 'compZipcode'
                            }}
                            handleChange={handleChange}
                            isError={isError}
                            disabled={values.radioGroupAddress2 === '0' ? false : true}
                        />
                    </Row>

                    <div style={{ textAlign: 'left' }}>
                        <p style={{ fontWeight: 300, fontSize: 16 }}>ข้อมูลที่ทำงาน</p>
                    </div>

                    <Career
                        values={{
                            careerType: values.careerType,
                            career: values.career
                        }}
                        name={{
                            careerType: 'careerType',
                            career: 'career'
                        }}
                        setValues={setValues}
                        isError={isError}
                        disabled={values.radioGroupAddress2 === '0' ? false : true}
                    />
                    <Row>
                        <Col xs="12" style={{ padding: 5 }}>
                            <Form.Group className="mb-2">
                                <Form.Control
                                    size="md"
                                    placeholder="ตำแหน่ง"
                                    type="text"
                                    name="compPosition"
                                    value={values.compPosition}
                                    onChange={handleChange}
                                    isInvalid={isError.err?.compPosition}
                                />
                                <Form.Control.Feedback type="invalid">
                                    <div className="d-flex">
                                        โปรดกรอกตำแหน่ง
                                    </div>
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>

                        <Col xs="12" style={{ padding: 5 }}>
                            <Form.Group className="mb-2">
                                <Form.Control
                                    size="md"
                                    placeholder="ฝ่ายงาน"
                                    type="text"
                                    name="part"
                                    value={values?.part || ""}
                                    onChange={handleChange}
                                    isInvalid={isError?.err?.part}
                                />
                                <Form.Control.Feedback type="invalid">
                                    <div className="d-flex">
                                        โปรดกรอกฝ่ายงาน
                                    </div>
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>

                        <Col xs="12" style={{ padding: 5 }}>
                            <Form.Group className="mb-2">
                                <Form.Control
                                    size="md"
                                    placeholder="บรรยายหน้าที่การงาน"
                                    type="text"
                                    name="describe_job_duties"
                                    value={values?.describe_job_duties || ""}
                                    onChange={handleChange}
                                    isInvalid={isError?.err?.describe_job_duties}
                                />
                                <Form.Control.Feedback type="invalid">
                                    <div className="d-flex">
                                        โปรดบรรยายหน้าที่การงาน
                                    </div>
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>

                        <Col xs="12" style={{ padding: 5 }}>
                            <Form.Group className="mb-2">
                                <Form.Control
                                    size="md"
                                    placeholder="อายุงาน (ปี)"
                                    type="text"
                                    name="work_age"
                                    value={formatNumberAllowText(values?.work_age)}
                                    onChange={handleChangeNumber}
                                    isInvalid={isError.err?.work_age}
                                />
                                <Form.Control.Feedback type="invalid">
                                    <div className="d-flex">
                                        โปรดกรอกอายุงาน
                                    </div>
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>

                        <Col xs="6" style={{ padding: 5 }}>
                            <Form.Group className="mb-2">
                                <Form.Control
                                    size="md"
                                    placeholder="อีเมล"
                                    type="email"
                                    name="compEmail"
                                    value={values.compEmail}
                                    onChange={handleChange}
                                    isInvalid={isError.err?.compEmail}
                                />
                                <Form.Control.Feedback type="invalid">
                                    <div className="d-flex">
                                        โปรดกรอกอีเมล
                                    </div>
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col xs="6" style={{ padding: 5 }}>
                            <Form.Group className="mb-2">
                                <Form.Control
                                    size="md"
                                    placeholder="เบอร์โทรที่ทำงาน"
                                    type="tel"
                                    name="compPhone"
                                    value={values.compPhone}
                                    onChange={handleChange}
                                    isInvalid={isError.err?.compPhone}
                                />
                                <Form.Control.Feedback type="invalid">
                                    <div className="d-flex">
                                        โปรดกรอกเบอร์โทรที่ทำงาน
                                    </div>
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col xs="12" style={{ padding: 5 }}>
                            <Form.Group className="mb-2">
                                <Form.Control
                                    size="md"
                                    placeholder="รายได้ (ประจำ)"
                                    type="text"
                                    name="mainIncome"
                                    value={formatNumber(values.mainIncome)}
                                    onChange={handleChangeNumber}
                                    isInvalid={isError.err?.mainIncome}
                                />
                                <Form.Control.Feedback type="invalid">
                                    <div className="d-flex">
                                        โปรดกรอกรายได้ (ประจำ)
                                    </div>
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col xs="12" style={{ padding: 5 }}>
                            <Form.Group className="mb-2">
                                <Form.Control
                                    size="md"
                                    placeholder="รายได้ (พิเศษ)"
                                    type="text"
                                    name="extraIncome"
                                    value={formatNumber(values.extraIncome)}
                                    onChange={handleChangeNumber}
                                    isInvalid={isError.err?.extraIncome}
                                />
                                <Form.Control.Feedback type="invalid">
                                    <div className="d-flex">
                                        โปรดกรอกรายได้ (พิเศษ)
                                    </div>
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12" style={{ padding: 5 }}>
                            <Form.Group className="mb-2">
                                <Form.Control
                                    size="md"
                                    placeholder="แหล่งที่มารายได้"
                                    type="text"
                                    name="incomeSource"
                                    value={values.incomeSource}
                                    onChange={handleChange}
                                    isInvalid={isError.err?.incomeSource}
                                />
                                <Form.Control.Feedback type="invalid">
                                    <div className="d-flex">
                                        โปรดกรอกแหล่งที่มารายได้
                                    </div>
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                    <div className="mt-5">
                        <Row>
                            <Button variant="secondary" type="submit" style={{ borderRadius: '10px' }}>ต่อไป</Button>
                            <Button variant="danger" style={{ borderRadius: '10px', marginTop: '20px', display: values.haveData ? '' : 'none' }} onClick={() => window.history.back()}>ยกเลิกแก้ไขข้อมูล</Button>
                        </Row>
                    </div>
                </Form.Group>
            </Form>
        </div>
    )
}

export default FormEditor3
