import React, { useEffect } from 'react'
import { Col, Row, Button, Form } from 'react-bootstrap';
import Address from './address';

const FormEditor2 = ({ isError, setIsError, values, setValues, setActiveStep, validateForm }) => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        if (values.radioGroupAddress === "1") {
            setValues((prevState) => ({
                ...prevState,
                currentAddress: values.address,
                currentProvince: values.province,
                currentCounty: values.county,
                currentDistrict: values.district,
                currentZipcode: values.zipcode,
            }));
        }
    }, [setValues,
        values.address,
        values.county,
        values.district,
        values.province,
        values.radioGroupAddress,
        values.zipcode
    ]);

    const handleSubmit = (event) => {
        event.preventDefault();

        const hasErrorValue = validateForm()
        if (hasErrorValue) return

        setActiveStep((prevActiveStep) => ({ current: prevActiveStep.current + 1, top: prevActiveStep.top + 1 }));
    };

    const handleChange = (event) => {
        const { name, value } = event.target
        const formattedValue = value.replace(/,/g, ''); // ลบจุลภาคออกก่อนเก็บใน state
        setValues((prevState) => ({
            ...prevState,
            [name]: formattedValue,
        }));
    }

    // function formatNumber(value) {
    //     if (!value) return "";
    //     return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    // }

    return (
        <div>
            <Form onSubmit={handleSubmit}>
                <Form.Group>
                    <Row>
                        <Col xs="12" className="d-flex align-items-left">
                            <Form.Check className='custom-radio-dot'>
                                <Form.Check.Input
                                    id="radioGroupAddress-0"
                                    type="radio"
                                    name="radioGroupAddress"
                                    value="0"
                                    checked={values.radioGroupAddress === '0'}
                                    onChange={() =>
                                        setValues((prevState) => ({
                                            ...prevState,
                                            radioGroupAddress: "0",
                                            currentAddress: "",
                                            currentProvince: "",
                                            currentCounty: "",
                                            currentDistrict: "",
                                            currentZipcode: "",
                                        }))
                                    }
                                    style={{
                                        width: '20px',
                                        height: '20px',
                                        borderRadius: '50px'
                                    }} />
                                <Form.Check.Label style={{
                                    marginLeft: '5px',
                                }}>
                                    <p style={{ textDecoration: 'none', fontWeight: 300 }}>
                                        กำหนดเอง
                                    </p>
                                </Form.Check.Label>
                            </Form.Check>
                        </Col>
                        <Col xs="12" className="d-flex align-items-left">
                            <Form.Check className='custom-radio-dot'>
                                <Form.Check.Input
                                    id="radioGroupAddress-1"
                                    type="radio"
                                    name="radioGroupAddress"
                                    value="1"
                                    checked={values.radioGroupAddress === '1'}
                                    onChange={() =>
                                        setValues((prevState) => ({
                                            ...prevState,
                                            radioGroupAddress: '1',
                                            currentAddress: values.address,
                                            currentProvince: values.province,
                                            currentCounty: values.county,
                                            currentDistrict: values.district,
                                            currentZipcode: values.zipcode,
                                        }))
                                    }
                                    style={{
                                        width: '20px',
                                        height: '20px',
                                        borderRadius: '50px'
                                    }} />
                                <Form.Check.Label style={{
                                    marginLeft: '5px',
                                }}>
                                    <p style={{ textDecoration: 'none', fontWeight: 300 }}>
                                        เป็นที่อยู่เดียวกับบัตรประชาชน
                                    </p>
                                </Form.Check.Label>
                            </Form.Check>
                        </Col>
                    </Row>
                    <br />
                    <p style={{ fontWeight: 300, fontSize: 16, textAlign: 'left' }}>ที่อยู่ปัจจุบัน</p>
                    <Address
                        values={{
                            address: values.currentAddress,
                            province: values.currentProvince,
                            county: values.currentCounty,
                            district: values.currentDistrict,
                            zipcode: values.currentZipcode
                        }}
                        name={{
                            address: 'currentAddress',
                            province: 'currentProvince',
                            county: 'currentCounty',
                            district: 'currentDistrict',
                            zipcode: 'currentZipcode'
                        }}
                        handleChange={handleChange}
                        isError={isError}
                        disabled={values.radioGroupAddress === '0' ? false : true}
                    />
                    <div className="mt-5">
                        <Row>
                            <Button variant="secondary" type="submit" style={{ borderRadius: '10px' }}>ต่อไป</Button>
                            <Button variant="danger" style={{ borderRadius: '10px', marginTop: '20px', display: values.haveData ? '' : 'none' }} onClick={() => window.history.back()}>ยกเลิกแก้ไขข้อมูล</Button>
                        </Row>
                    </div>
                </Form.Group>
            </Form>
        </div>
    )
}

export default FormEditor2
