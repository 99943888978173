import { SyncLoader } from 'react-spinners';

export default function Loading() {
    return (
        <>
            <SyncLoader
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                }}
                color="#ffffff"
                cssOverride={{}}
                loading
                margin={3}
                size={12}
            />
        </>
    );
}
