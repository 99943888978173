import React from 'react'
import Success from '../assets/images/1.png';
import Header from './../components/header/normal_logo'

const UploadSuccess = () => {
    return (
        <div className='center' style={{
            maxWidth: '30rem',
            minHeight: '100vh',
            boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px'
        }}>
            <Header/>
            <div style={{ padding: '45px' }}>
                <div className='m-3'>
                    <img src={Success} alt="success" width="62px" />
                </div>

                <br />
                <h3>สมัครเสร็จสิ้น</h3>
            </div>
        </div>
    )
}

export default UploadSuccess
