
import configCashkrub from './config/cashkrub.json';
import configMscw from './config/mscw.json';
import LogoMscw from './assets/images/logo_brand/logo_ms.png';
import LogoCashkrub from './assets/images/logo_brand/logo_cashkrub.png';
import LogoNVCash from './assets/images/logo_brand/logo_nvcash.png';
import LogoEasyCapital from './assets/images/logo_brand/logo_easy_capital.png';

const groupsCashkrub = [
    'ALIX',
    'TARNA',
    'MALIBU',
    'SHASA',
    'WISHMOTORS',
    'MOU-SCAN',
    'MOU-THAI-JAPAN',
    'MOU-SIBA',
    'MOU-XINGHUA',
    'MOU-AKS',
    'CASHKRUB'
];

const groupsNVcash = [
    'NVCASH'
];

const groupsEasyCapital = [
    'EASY-CAPITAL'
];

export function padLeadingZeros(num, size) {
    var s = num + "";
    while (s.length < size) s = "0" + s;
    return s;
}

export function thaiFullDateFormatDayJs(dayjs) {
    const thaiDayName = ["อาทิตย์", "จันทร์", "อังคาร", "พุธ", "พฤหัสบดี", "ศุกร์", "เสาร์"]
    const thaiMonthName = ["มกราคม", "กุมภาพันธ์", "มีนาคม", "เมษายน", "พฤษภาคม", "มิถุนายน", "กรกฎาคม", "สิงหาคม", "กันยายน", "ตุลาคม", "พฤศจิกายน", "ธันวาคม"]

    return "วัน" + thaiDayName[dayjs.day()] + "ที่ " + dayjs.date() + " " + thaiMonthName[dayjs.month()] + " " + (dayjs.year() + 543)
}

export function validateIDCard(idCardNumber) {
    const idCardPattern = /^[0-9]{13}$/;
    if (!idCardPattern.test(idCardNumber)) {
        return false
    }

    const digits = idCardNumber.split('').map(Number);
    const weights = [13, 12, 11, 10, 9, 8, 7, 6, 5, 4, 3, 2];

    let sum = 0;
    for (let i = 0; i < 12; i++) {
        sum += digits[i] * weights[i];
    }

    const remainder = sum % 11;
    const checksum = (11 - remainder) % 10;

    return (checksum === digits[12]);
};

export function formatIDCardNumber(input) {
    // Remove non-numeric characters
    const numericInput = input.replace(/\D/g, '');

    const maxLength = 13;
    const truncatedInput = numericInput.slice(0, maxLength);
    // Check if the input is not empty
    if (truncatedInput.length > 0) {
        let formattedInput = '';

        if (truncatedInput.length <= 2) {
            formattedInput = truncatedInput.replace(/^(\d{1})(\d{1})$/, '$1-$2');
        } else if (truncatedInput.length <= 5) {
            formattedInput = truncatedInput.replace(/^(\d{1})(\d{1,4})$/, '$1-$2');
        } else if (truncatedInput.length <= 10) {
            formattedInput = truncatedInput.replace(/^(\d{1})(\d{4})(\d{1,5})$/, '$1-$2-$3');
        } else if (truncatedInput.length <= 12) {
            formattedInput = truncatedInput.replace(/^(\d{1})(\d{4})(\d{5})(\d{1,2})$/, '$1-$2-$3-$4');
        } else {
            formattedInput = truncatedInput.replace(/^(\d{1})(\d{4})(\d{5})(\d{2})(\d{1})$/, '$1-$2-$3-$4-$5');
        }

        return formattedInput;
    }

    return '';
};

export function changeBackgroundConfig() {
    const brand = localStorage.getItem("brand");

    if (groupsCashkrub.includes(brand)) {
        document.documentElement.style.setProperty("--body-background-color", "#4d4d4d")
        document.documentElement.style.setProperty("--btn-select-md-background-color", "rgb(1 192 171)")
        document.documentElement.style.setProperty("--btn-select-md-border", "2px solid rgb(1 192 171)")
        document.documentElement.style.setProperty("--custom-button-2-background-color", "#fff")
        document.documentElement.style.setProperty("--herder-subject", "rgb(1 192 171)")
        document.documentElement.style.setProperty("--herder-subject-background-color", "rgb(1 192 171)")
        document.documentElement.style.setProperty("--my-screen-background-color", "#4d4d4d")
        document.documentElement.style.setProperty("--custom-radio-dot-background-color", "rgb(1 192 171)")
        document.documentElement.style.setProperty("--custom-radio-dot-border-color", "rgb(1 192 171)")
        document.documentElement.style.setProperty("--custom-radio-dot-box-shadow", "0 0 0 0.2rem rgba(1, 192, 171, 0.2)")
        document.documentElement.style.setProperty("--custom-button-signature-background-color", "rgb(1 192 171)")
        document.documentElement.style.setProperty("--modal-content-background-color", "#4d4d4d")
    } else if (groupsNVcash.includes(brand)) {
        document.documentElement.style.setProperty("--body-background-color", "#4d4d4d")
        document.documentElement.style.setProperty("--btn-select-md-background-color", "rgb(1 192 171)")
        document.documentElement.style.setProperty("--btn-select-md-border", "2px solid rgb(1 192 171)")
        document.documentElement.style.setProperty("--custom-button-2-background-color", "#fff")
        document.documentElement.style.setProperty("--herder-subject", "rgb(1 192 171)")
        document.documentElement.style.setProperty("--herder-subject-background-color", "rgb(1 192 171)")
        document.documentElement.style.setProperty("--my-screen-background-color", "#4d4d4d")
        document.documentElement.style.setProperty("--custom-radio-dot-background-color", "rgb(1 192 171)")
        document.documentElement.style.setProperty("--custom-radio-dot-border-color", "rgb(1 192 171)")
        document.documentElement.style.setProperty("--custom-radio-dot-box-shadow", "0 0 0 0.2rem rgba(1, 192, 171, 0.2)")
        document.documentElement.style.setProperty("--custom-button-signature-background-color", "rgb(1 192 171)")
        document.documentElement.style.setProperty("--modal-content-background-color", "#4d4d4d")
    }
}

export function changeConfigSource() {
    const brand = localStorage.getItem("brand");
    let configSource

    if (groupsCashkrub.includes(brand)) {
        configSource = configCashkrub
        configSource.logo.image = LogoCashkrub
    } else if (groupsNVcash.includes(brand)) {
        configSource = configCashkrub  // same cashkrub
        configSource.logo.image = LogoNVCash
    } else if (groupsEasyCapital.includes(brand)) {
        configSource = configMscw // same mscw
        configSource.logo.image = LogoEasyCapital
    } else {
        // default mscw
        configSource = configMscw
        configSource.logo.image = LogoMscw
    }

    return configSource
}
