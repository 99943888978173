import React, { useEffect } from 'react'
import { Col, Row, Button, Form } from 'react-bootstrap';
import Address from './address';


const FormEditor4 = ({ isError, setIsError, values, setValues, setActiveStep, validateForm }) => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const handleSubmit = (event) => {
        event.preventDefault();

        const hasErrorValue = validateForm()
        if (hasErrorValue) return

        setActiveStep((prevActiveStep) => ({ current: prevActiveStep.current + 1, top: prevActiveStep.top + 1 }));
    };

    const handleChange = (event) => {
        const { name, value } = event.target
        const formattedValue = value.replace(/,/g, ''); // ลบจุลภาคออกก่อนเก็บใน state
        setValues((prevState) => ({
            ...prevState,
            [name]: formattedValue,
        }));
    }

    const handleChangeNumber = (event) => {
        const { name, value } = event.target;
        // ลบจุลภาคและสิ่งที่ไม่ใช่ตัวเลขออก
        const formattedValue = value.replace(/,/g, '').replace(/\D/g, '');
    
        setValues((prevState) => ({
            ...prevState,
            [name]: formattedValue,
        }));
    };

    function formatNumber(value) {
        if (!value) return "";
        value =  value.replace(/\D/g, ''); // ใช้ regex เพื่อเก็บเฉพาะตัวเลข
        return value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } 

    return (
        <div>
            <Form onSubmit={handleSubmit}>
                <Form.Group>
                    <p style={{ fontWeight: 300, fontSize: 16, textAlign: 'left' }}>สถานภาพ</p>
                    <Row>
                        <Col xs="3" className="d-flex align-items-left">
                            <Form.Check className='custom-radio-dot'>
                                <Form.Check.Input id="radioOne" type="radio" name="status" value="โสด" checked={values.status === "โสด"} onChange={handleChange}
                                    style={{
                                        width: '20px',
                                        height: '20px',
                                        borderRadius: '50px'
                                    }} />
                                <Form.Check.Label style={{
                                    marginLeft: '5px',
                                }}>
                                    <p style={{ textDecoration: 'none', fontWeight: 300 }}>
                                        โสด
                                    </p>
                                </Form.Check.Label>
                            </Form.Check>
                        </Col>

                        <Col xs="3" className="d-flex align-items-left">
                            <Form.Group>
                                <Form.Check className='custom-radio-dot'>
                                    <Form.Check.Input id="radioTwo" type="radio" name="status" value="สมรส" checked={values.status === "สมรส"} onChange={handleChange}
                                        style={{
                                            width: '20px',
                                            height: '20px',
                                            borderRadius: '50px'
                                        }} />
                                    <Form.Check.Label style={{
                                        marginLeft: '5px',
                                    }}>
                                        <p style={{ textDecoration: 'none', fontWeight: 300 }}>
                                            สมรส
                                        </p>
                                    </Form.Check.Label>
                                </Form.Check>
                            </Form.Group>
                        </Col>
                        <Col xs="6" className="d-flex align-items-left">
                            <Form.Group>
                                <Form.Check className='custom-radio-dot'>
                                    <Form.Check.Input id="radioThree" type="radio" name="status" value="หย่าร้าง" checked={values.status === "หย่าร้าง"} onChange={handleChange}
                                        style={{
                                            width: '20px',
                                            height: '20px',
                                            borderRadius: '50px'
                                        }} />
                                    <Form.Check.Label style={{
                                        marginLeft: '5px',
                                    }}>
                                        <p style={{ textDecoration: 'none', fontWeight: 300 }}>
                                            หย่าร้าง
                                        </p>
                                    </Form.Check.Label>
                                </Form.Check>
                            </Form.Group>
                        </Col>
                        <Col xs="3" className="d-flex align-items-left">
                            <Form.Group>
                                <Form.Check className='custom-radio-dot'>
                                    <Form.Check.Input id="radioFour" type="radio" name="status" value="อื่นๆ" checked={!(values.status === 'โสด' || values.status === 'สมรส' || values.status === 'หย่าร้าง') ? true : false} onChange={handleChange}
                                        style={{
                                            width: '20px',
                                            height: '20px',
                                            borderRadius: '50px'
                                        }} />
                                    <Form.Check.Label style={{
                                        marginLeft: '5px',
                                    }}>
                                        <p style={{ textDecoration: 'none', fontWeight: 300 }}>
                                            อื่นๆ
                                        </p>
                                    </Form.Check.Label>
                                </Form.Check>
                            </Form.Group>
                        </Col>
                        <Col xs="9" style={{ padding: 5, marginTop: '-0.5rem' }}>
                            <Form.Group className="mb-2">
                                <Form.Control
                                    size="md"
                                    placeholder="ระบุ"
                                    type="text"
                                    name="status"
                                    value={values.status === 'อื่นๆ' ? "" : values.status}
                                    onChange={handleChange}
                                    disabled={(values.status === 'โสด' || values.status === 'สมรส' || values.status === 'หย่าร้าง') ? true : false}
                                    isInvalid={isError.err?.status}
                                />
                                <Form.Control.Feedback type="invalid">
                                    <div className="d-flex">
                                        {isError.message?.status}
                                    </div>
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                    <div style={{ display: values.status === 'สมรส' ? 'block' : 'none' }}>
                        <p style={{ fontWeight: 300, fontSize: 16, textAlign: 'left' }}>ข้อมูลคู่สมรส</p>
                        <Row>
                            <Col xs="12" style={{ padding: 5 }}>
                                <Form.Group className="mb-2">
                                    <Form.Control
                                        size="md"
                                        placeholder="ชื่อ"
                                        type="text"
                                        name="spouseName"
                                        value={values.spouseName}
                                        onChange={(event) => {
                                            const toggleConsent = values.fconsent;

                                            setValues((prevState) => ({
                                                ...prevState,
                                                [event.target.name]: event.target.value,
                                                collectName: toggleConsent ? event.target.value : "",
                                            }));
                                        }}
                                        isInvalid={isError.err?.spouseName}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        <div className="d-flex">
                                            {isError.message?.spouseName}
                                        </div>
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col xs="12" style={{ padding: 5 }}>
                                <Form.Group className="mb-2">
                                    <Form.Control
                                        size="md"
                                        placeholder="นามสกุล"
                                        type="text"
                                        name="spouseSurname"
                                        value={values.spouseSurname}
                                        onChange={(event) => {
                                            const toggleConsent = values.fconsent;

                                            setValues((prevState) => ({
                                                ...prevState,
                                                [event.target.name]: event.target.value,
                                                collectSurname: toggleConsent ? event.target.value : "",
                                            }));
                                        }}
                                        isInvalid={isError.err?.spouseSurname}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        <div className="d-flex">
                                            {isError.message?.spouseSurname}
                                        </div>
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col xs="12" style={{ padding: 5 }}>
                                <Form.Group className="mb-2">
                                    <Form.Control
                                        size="md"
                                        placeholder="นามสกุลเดิม"
                                        type="text"
                                        name="spouseOldSurname"
                                        value={values.spouseOldSurname}
                                        onChange={handleChange}
                                        isInvalid={isError.err?.spouseOldSurname}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        <div className="d-flex">
                                            {isError.message?.spouseOldSurname}
                                        </div>
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col xs="12" style={{ padding: 5 }}>
                                <Form.Group className="mb-2">
                                    <Form.Control
                                        size="md"
                                        placeholder="ชื่อบริษัท/โรงงาน"
                                        type="text"
                                        name="spouseComp"
                                        value={values.spouseComp}
                                        onChange={(event) => {
                                            const toggleConsent = values.fconsent;

                                            setValues((prevState) => ({
                                                ...prevState,
                                                [event.target.name]: event.target.value,
                                                collectComp: toggleConsent ? event.target.value : "",
                                            }));
                                        }}
                                        isInvalid={isError.err?.spouseComp}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        <div className="d-flex">
                                            {isError.message?.spouseComp}
                                        </div>
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Address
                                values={{
                                    address: values.spouseAddress,
                                    province: values.spouseProvince,
                                    county: values.spouseCounty,
                                    district: values.spouseDistrict,
                                    zipcode: values.spouseZipcode
                                }}
                                name={{
                                    address: 'spouseAddress',
                                    province: 'spouseProvince',
                                    county: 'spouseCounty',
                                    district: 'spouseDistrict',
                                    zipcode: 'spouseZipcode'
                                }}
                                handleChange={handleChange}
                                isError={isError}
                                disabled={false}
                            />
                            <Col xs="6" style={{ padding: 5 }}>
                                <Form.Group className="mb-2">
                                    <Form.Control
                                        size="md"
                                        placeholder="เบอร์มือถือ"
                                        type="tel"
                                        name="spousePhone"
                                        value={values.spousePhone}
                                        onChange={(event) => {
                                            const toggleConsent = values.fconsent;

                                            setValues((prevState) => ({
                                                ...prevState,
                                                [event.target.name]: event.target.value,
                                                collectPhone: toggleConsent ? event.target.value : "",
                                            }));
                                        }}
                                        isInvalid={isError.err?.spousePhone}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        <div className="d-flex">
                                            {isError.message?.spousePhone}
                                        </div>
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col xs="6" style={{ padding: 5 }}>
                                <Form.Group className="mb-2">
                                    <Form.Control
                                        size="md"
                                        placeholder="เบอร์โทรที่ทำงาน"
                                        type="tel"
                                        name="spouseCompPhone"
                                        value={values.spouseCompPhone}
                                        onChange={handleChange}
                                        isInvalid={isError.err?.spouseCompPhone}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        <div className="d-flex">
                                            {isError.message?.spouseCompPhone}
                                        </div>
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col xs="12" style={{ padding: 5 }}>
                                <Form.Group className="mb-2">
                                    <Form.Control
                                        size="md"
                                        placeholder="รายได้ต่อเดือน"
                                        type="text"
                                        name="spouseIncome"
                                        value={formatNumber(values.spouseIncome)}
                                        onChange={handleChangeNumber}
                                        isInvalid={isError.err?.spouseIncome}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        <div className="d-flex">
                                            {isError.message?.spouseIncome}
                                        </div>
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                            <Col xs="12" style={{ padding: 5 }}>
                                <Form.Group className="mb-2">
                                    <Form.Control
                                        size="md"
                                        placeholder="คู่สมรสช่วยเหลือครอบครัว (บาท)"
                                        type="text"
                                        name="help_the_family"
                                        value={formatNumber(values?.help_the_family)}
                                        onChange={handleChangeNumber}
                                        isInvalid={isError?.err?.help_the_family}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        <div className="d-flex">
                                            {isError?.message?.help_the_family}
                                        </div>
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                            <Col xs="12" className="d-flex align-items-left">
                                <Form.Group>
                                    <Form.Check className='custom-radio-dot'>
                                        <Form.Check.Input
                                            type="checkbox"
                                            name="fconsent"
                                            value={values.fconsent}
                                            checked={values.fconsent}
                                            onChange={(event) => {
                                                const toggleConsent = !values.fconsent;

                                                setValues((prevState) => ({
                                                    ...prevState,
                                                    collectName: toggleConsent ? values.spouseName : "",
                                                    collectSurname: toggleConsent ? values.spouseSurname : "",
                                                    collectPhone: toggleConsent ? values.spousePhone : "",
                                                    collectRelation: toggleConsent ? "คู่สมรส" : "",
                                                    collectComp: toggleConsent ? values.spouseComp : "",
                                                    [event.target.name]: toggleConsent,
                                                }));
                                            }}
                                            style={{
                                                width: '20px',
                                                height: '20px',
                                                borderRadius: '50px'
                                            }} />
                                        <Form.Check.Label style={{
                                            marginLeft: '5px',
                                        }}>
                                            <p style={{ textDecoration: 'none', textAlign: 'left', fontWeight: 300 }}>
                                                เป็นบุคคลที่สามารถติดตามทวงหนี้ได้
                                            </p>
                                        </Form.Check.Label>
                                    </Form.Check>
                                </Form.Group>
                            </Col>
                        </Row>
                    </div>

                    <p style={{ fontWeight: 300, fontSize: 16, textAlign: 'left' }}>บุคคลที่สามารถติดตามทวงหนี้ได้</p>
                    <div>
                        <Row>
                            <Col xs="12" style={{ padding: 5 }}>
                                <Form.Group className="mb-2">
                                    <Form.Control
                                        size="md"
                                        placeholder="ชื่อ"
                                        type="text"
                                        name="collectName"
                                        value={values.collectName}
                                        onChange={handleChange}
                                        isInvalid={isError.err?.collectName}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        <div className="d-flex">
                                            {isError.message?.collectName}
                                        </div>
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col xs="12" style={{ padding: 5 }}>
                                <Form.Group className="mb-2">
                                    <Form.Control
                                        size="md"
                                        placeholder="นามสกุล"
                                        type="text"
                                        name="collectSurname"
                                        value={values.collectSurname}
                                        onChange={handleChange}
                                        isInvalid={isError.err?.collectSurname}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        <div className="d-flex">
                                            {isError.message?.collectSurname}
                                        </div>
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col xs="6" style={{ padding: 5 }}>
                                <Form.Group className="mb-2">
                                    <Form.Control
                                        size="md"
                                        placeholder="เบอร์มือถือ"
                                        type="tel"
                                        name="collectPhone"
                                        value={values.collectPhone}
                                        onChange={handleChange}
                                        isInvalid={isError.err?.collectPhone}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        <div className="d-flex">
                                            {isError.message?.collectPhone}
                                        </div>
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col xs="6" style={{ padding: 5 }}>
                                <Form.Group className="mb-2">
                                    <Form.Control
                                        size="md"
                                        placeholder="ความสัมพันธ์"
                                        type="text"
                                        name="collectRelation"
                                        value={values.collectRelation}
                                        onChange={handleChange}
                                        isInvalid={isError.err?.collectRelation}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        <div className="d-flex">
                                            {isError.message?.collectRelation}
                                        </div>
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col xs="12" style={{ padding: 5 }}>
                                <Form.Group className="mb-2">
                                    <Form.Control
                                        size="md"
                                        placeholder="ชื่อที่ทำงาน"
                                        type="text"
                                        name="collectComp"
                                        value={values.collectComp}
                                        onChange={handleChange}
                                        isInvalid={isError.err?.collectComp}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        <div className="d-flex">
                                            {isError.message?.collectComp}
                                        </div>
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                            <Col xs="12" className="d-flex align-items-left">
                                <Form.Group>
                                    <Form.Check className='custom-radio-dot'>
                                        <Form.Check.Input type="checkbox" name="pdpa" checked={Boolean(values.pdpa)} onChange={(event) => {
                                            setValues({
                                                ...values,
                                                [event.target.name]: !values.pdpa,
                                            });
                                        }}
                                            style={{
                                                width: '20px',
                                                height: '20px',
                                                borderRadius: '50px'
                                            }} />
                                        <Form.Check.Label style={{
                                            marginLeft: '10px',
                                        }}>
                                            <p style={{ textDecoration: 'none', textAlign: 'left', fontWeight: 300 }}>
                                                ยินยอมให้บริษัทเปิดเผยข้อมูลส่วนบุคคลรวมถึงข้อมูลอื่นๆ เพื่อทวงถามหนี้
                                            </p>
                                        </Form.Check.Label>
                                    </Form.Check>
                                </Form.Group>
                            </Col>
                        </Row>

                        <div className="mt-4">
                            <Row>
                                <Button variant="secondary" type="submit" style={{ borderRadius: '10px' }} disabled={!values.pdpa}>ต่อไป</Button>
                                <Button variant="danger" style={{ borderRadius: '10px', marginTop: '20px', display: values.haveData ? '' : 'none' }} onClick={() => window.history.back()}>ยกเลิกแก้ไขข้อมูล</Button>
                            </Row>
                        </div>
                    </div>
                </Form.Group>
            </Form>
        </div>
    )
}

export default FormEditor4
