import React, { useEffect, useState } from 'react';
import Loading from '../components/loading';
import { verifyGateway } from '../services/gb-services';
import { useParams } from 'react-router-dom';
import Header from './../components/header/normal_logo'

export default function Gateway() {
    const [isLoading, setIsLoading] = useState(true);
    const [gateway, setGateway] = useState();
    const { bank, id } = useParams()

    useEffect(() => {
        verifyGateway({ line_id: id, bank: bank })
            .then((res) => {
                console.log('res', res)
                if (res.success) {
                    setGateway(res);
                    setIsLoading(false);
                } else {
                    window.location.href = '/502'
                }
            })
            .catch((err) => {
                console.error(err);
                window.location.href = '/502'
            });
    }, [bank, id]);

    const handleSubmit = async () => {
        window.location.href = gateway.data?.regis_link
        setIsLoading(true);
    };

    if (isLoading) return <Loading />

    return (
        <div className="center" style={{ maxWidth: '48rem' }}>
            <Header />
            <div style={{ padding: '32px' }}>
                <p className="p-2" style={{ fontWeight: 400, fontSize: '18px' }}>เงื่อนไขค่าธรรมเนียมบริการชำระสินเชื่ออัตโนมัติ</p>

                <div>
                    <div style={{ textAlign: 'left', fontWeight: 400, fontSize: '14px' }}>
                        <p>
                            &emsp;&emsp;กรณีที่ลูกค้าได้รับการอนุมัติสินเชื่อ ลูกค้าตกลงจะชำระสินเชื่อผ่านบริการชำระสินเชื่ออัตโนมัติ จนกว่าจะชำระสินเชื่อเสร็จสิ้น
                            อัตราค่าธรรมเนียมการใช้บริการดังกล่าว จะชำระให้กับบริษัท เซ็นดิท เทค จำกัด ซึ่งเป็นบุคคลที่ 3 (service provider) ที่ได้รับการรับรองในฐานะผู้ให้บริการดังกล่าว โดยมีการหักค่างวดอัตโนมัติกับธนาคาร ดังนี้
                        </p>
                        <ul style={{ color: "#fff" }}>
                            <li>ธนาคาร กสิกรไทย ค่าธรรมเนียมการตัดเงิน จำนวน 10.70 บาท/ต่อรายการ</li>
                            <li>ธนาคาร ไทยพาณิชย์, กรุงศรีอยุธยา, กรุงเทพ, กรุงไทย ค่าธรรมเนียมการตัดเงิน จำนวน 7.49 บาท/ต่อรายการ</li>
                        </ul>
                    </div>

                    <button className="btn btn-secondary mt-4 mb-4" onClick={() => handleSubmit()}>ยินยอมและดำเนินการสมัครบริการชำระสินเชื่ออัตโนมัติ</button>
                </div>
            </div>
        </div>
    );
}
