import React, { useEffect, useState } from 'react'
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import Stack from 'react-bootstrap/Stack';
import Stepper from '../../components/form-editor/stepper';
import FormEditor from '../../components/form-editor'
import Cookies from 'js-cookie';
import Loading from '../../components/loading';
import * as userHttpServices from '../../services/digital-app-services';
import { validPhone } from '../../../src/assets/regex';
// import { validPhone, validCompPhone } from '../../../src/assets/regex';
// import getData from '../../assets/jsonData.json'
import { changeConfigSource, validateIDCard } from '../../utils';

const Form = () => {
    const brand = localStorage.getItem('brand')
    const product = localStorage.getItem('product')
    const document = localStorage.getItem('document')
    const ncbStatus = localStorage.getItem('ncb_status')
    const phone = localStorage.getItem('phone')

    const configSource = changeConfigSource()
    const [activeStep, setActiveStep] = useState({ current: 1, top: 0 });
    const [isLoading, setIsLoading] = useState(true)
    const [isError, setIsError] = useState({})
    const [values, setValues] = useState({
        // form1
        prefix: "",
        gen_der: "",
        firstName: "",
        lastName: "",
        birthDate: "",
        birthMonth: "",
        birthYear: "",
        identity: "",
        residence: "",
        phone: phone || "",
        line: "",
        email: "",
        facebook: "",
        address: "",
        province: "",
        county: "",
        district: "",
        zipcode: "",
        educational: "",

        // form2
        radioGroupAddress: "0",
        currentAddress: "",
        currentProvince: "",
        currentCounty: "",
        currentDistrict: "",
        currentZipcode: "",

        // form3
        radioGroupAddress2: "0",
        compType: "",
        compName: "",
        compAddress: "",
        compProvince: "",
        compCounty: "",
        compDistrict: "",
        compZipcode: "",
        careerType: "",
        career: "",
        compPosition: "",
        compEmail: "",
        compPhone: "",
        mainIncome: "",
        extraIncome: "",
        incomeSource: "",
        part: "",
        describe_job_duties: "",
        work_age: "",

        // form4
        status: "โสด",
        spouseName: "",
        spouseSurname: "",
        spouseOldSurname: "",
        spouseComp: "",
        spouseAddress: "",
        spouseProvince: "",
        spouseCounty: "",
        spouseDistrict: "",
        spouseZipcode: "",
        spousePhone: "",
        spouseCompPhone: "",
        spouseIncome: "",
        collectName: "",
        collectSurname: "",
        collectPhone: "",
        collectRelation: "",
        collectComp: "",
        fconsent: false,
        pdpa: false,
        help_the_family: "",

        // form5
        totalDebt: "",
        monthlyInstallments: "",
        amountReq: "",
        payAble: "",
        objective: "",
        bank: "",
        account: "",
        accounttype: "",
        accountname: "",

        // form6
        consent1: false,
        consent2: false,
        consent3: false,
        consent4: false,
        consent5: false,
        consent6: false,

        haveData: false,

        brand: brand ?? 'MSCW',
        product: product ?? 'PLOAN'
    })

    // useEffect(() => {
    //     if (window.confirm("GET DATA")) {
    //         console.log("User clicked OK");
    //         setIsLoading(false)
    //         setActiveStep((prevActiveStep) => ({ current: 6, top: 6 }));
    //         setValues(() => ({
    //             ...getData
    //         }))

    //     } else {
    //         console.log("User clicked Cancel");
    //         setIsLoading(false)
    //     }
    // }, [])

    useEffect(() => {
        const lineAccessToken = Cookies.get('lineAccessToken');
        console.log('lineAccessToken',lineAccessToken)

        userHttpServices.lineLogin(lineAccessToken)
            .then((res) => {
                console.log('response', res);

                if (res?.data?.data) {
                    const userDetail = res?.data?.data?.user_form_data && res?.data?.data?.user_form_data[0]?.user_detail
                    console.log('userDetail',userDetail)

                    setActiveStep((prevActiveStep) => ({ ...prevActiveStep, top: 6 }));
                    setValues((prev) => ({
                        ...prev,
                        ...userDetail?.formData1,
                        ...userDetail?.formData2,
                        ...userDetail?.formData3,
                        ...userDetail?.formData4,
                        ...userDetail?.formData5,
                        ...userDetail?.formData5,

                        educational: userDetail?.formData1?.educational === "มป" ? "ปวช" : userDetail?.formData1?.educational,
                        prefix: userDetail?.formData1?.prefix ?? userDetail?.formData1?.gender,
                        careerType: userDetail?.formData3?.career,
                        fconsent: Boolean(userDetail?.formData4?.career),
                        pdpa: Boolean(userDetail?.formData4?.pdpa),
                        consent1: true,
                        consent2: true,
                        consent3: true,
                        consent4: true,
                        consent5: true,
                        consent6: true,

                        haveData: true,
                        data: res?.data?.data
                    }));
                }

                setIsLoading(false)
            })
            .catch((error) => alert('[UD L183] An error occurred:', error));
    }, []); 

    const handleRegister = async () => {
        setIsLoading(true)
        const lineAccessToken = Cookies.get('lineAccessToken');

        const str = window.navigator.userAgent
        let OS

        try {
            if (str.match('Android')) {
                OS = 'ANDROID'
            } else if (str.match('iPhone')) {
                OS = 'IOS'
            } else {
                OS = 'ANDROID'
            }
        } catch {
            OS = 'ANDROID'
            alert('ขออภัยในความผิดพลาด กรุณาติดต่อแอดมิน เพื่อดำเนินการแก้ไข - UOS-L112 ')
        }

        const registerData = {
            id_card_no: values.identity,
            name: values.firstName,
            surname: values.lastName,
            phone_number: values.phone,
            pdpa_consent: values.pdpa ? '1' : '0',
            user_detail: {
                formData1: {
                    os: OS,
                    gen_der: values.gen_der,
                    prefix: values.prefix,
                    firstName: values.firstName,
                    lastName: values.lastName,
                    birthDate: values.birthDate,
                    birthMonth: values.birthMonth,
                    birthYear: values.birthYear,
                    identity: values.identity,
                    phone: values.phone,
                    line: values.line,
                    email: values.email,
                    facebook: values.facebook,
                    address: values.address,
                    province: values.province,
                    zipcode: values.zipcode,
                    county: values.county,
                    district: values.district,
                    educational: values.educational,
                    residence: values.residence,
                },
                formData2: {
                    radioGroupAddress: values.radioGroupAddress,
                    currentAddress: values.currentAddress,
                    currentProvince: values.currentProvince,
                    currentCounty: values.currentCounty,
                    currentDistrict: values.currentDistrict,
                    currentZipcode: values.currentZipcode,
                },
                formData3: {
                    radioGroupAddress2: values.radioGroupAddress2,
                    compType: values.compType,
                    compName: values.compName,
                    compAddress: values.compAddress,
                    compProvince: values.compProvince,
                    compCounty: values.compCounty,
                    compDistrict: values.compDistrict,
                    compZipcode: values.compZipcode,
                    career: values.career,
                    compPosition: values.compPosition,
                    compEmail: values.compEmail,
                    compPhone: values.compPhone,
                    mainIncome: values.mainIncome,
                    extraIncome: values.extraIncome,
                    incomeSource: values.incomeSource,
                    part: values.part,
                    describe_job_duties: values.describe_job_duties,
                    work_age: values.work_age,
                },
                formData4: {
                    status: values.status,
                    spouseName: values.spouseName,
                    spouseSurname: values.spouseSurname,
                    spouseOldSurname: values.spouseOldSurname,
                    spouseComp: values.spouseComp,
                    spouseAddress: values.spouseAddress,
                    spouseProvince: values.spouseProvince,
                    spouseZipcode: values.spouseZipcode,
                    spouseCounty: values.spouseCounty,
                    spouseDistrict: values.spouseDistrict,
                    spousePhone: values.spousePhone,
                    spouseCompPhone: values.spouseCompPhone,
                    spouseIncome: values.spouseIncome,
                    help_the_family: values.help_the_family,
                    collectName: values.collectName,
                    collectSurname: values.collectSurname,
                    collectPhone: values.collectPhone,
                    collectRelation: values.collectRelation,
                    collectComp: values.collectComp,
                    fconsent: true,
                    pdpa: true,
                },
                formData5: {
                    totalDebt: values.totalDebt,
                    monthlyInstallments: values.monthlyInstallments,
                    amountReq: values.amountReq,
                    payAble: values.payAble,
                    objective: values.objective,
                    bank: values.bank,
                    account: values.account,
                    accounttype: values.accounttype,
                    accountname: values.accountname,
                },
                consent: {
                    consent1: true,
                    consent2: true,
                    consent3: true,
                    consent4: true,
                    consent5: true,
                    consent6: true,
                },
            },
            brand: values.brand,
            product: values.product
        }

        if (!values.haveData) {
            // create
            if (document && ncbStatus) {

                const body = {
                    firstname: values.firstName,
                    lastname: values.lastName,
                    email: values.phone + '@mscw.com',
                    hs_analytics_source: 'DIRECT_TRAFFIC',
                    customer: 1,
                    paper: document,
                    credit_line: ncbStatus,
                    phone: values.phone,
                    lha_line_traffic_source: 'MSCW_DIGITALAPP',
                };

                console.log('body of updateHupspot', body)

                const res1 = await userHttpServices.updateHupspot(body)
                    .then((res) => {
                        console.log('Hupspot data updated successfully', res);
                        return res
                    })
                    .catch((error) => {
                        console.error('Hupspot data update error:', error);
                        return error
                    });

                console.log("res1", res1)

                if (res1?.data?.success) {
                    const body = {
                        customer_info: {
                            title: values?.prefix ?? values?.gender,
                            firstName: values?.firstName,
                            lastName: values?.lastName,
                            phoneNumber: values?.phone,
                        },
                        detail: {
                            // "identity": values.identity,
                            registerType: 1,
                            buroStatus: ncbStatus,
                            isDocumentPass: document,
                            band: "MSCW",
                            marketingChannel: 'OTHER',
                            agency: 'OTHER',
                        }
                    };

                    console.log('body of updatePreScreenData', body)

                    const res2 = userHttpServices.updatePreScreen(body)
                        .then((res) => {
                            console.log('Pre-screen data updated successfully', res);
                        })
                        .catch((error) => {
                            console.error('Pre-screen data update error:', error);
                        });

                    console.log("res2", res2)
                }
            }

            userHttpServices.lineRegister(lineAccessToken, registerData)
                .then((res) => {
                    console.log('lineRegister', res);
                    window.location.href = "/signature";
                })
                .catch((error) => {
                    console.error('Line registration error:', error);
                    alert('Line registration error:', error)
                });

            setIsLoading(false)
        } else {
            // edit
            const registerData2 = {
                user_id: values.data.detail[0].user_id,
                contact_id: values.data.detail[0].contact_id,
                user_form_detail: {
                    _id: values.data.user_form_data[0]._id,
                    contact_id: values.data.user_form_data[0].contact_id,
                    user_id: values.data.user_form_data[0].user_id,
                    user_detail: registerData.user_detail
                }
            }

            userHttpServices.editUser(registerData2)
                .then((res) => {
                    console.log(res)
                    if (res.data.success) {
                        window.location.href = '/signature'
                    } else {
                        alert('แก้ไขข้อมูลไม่สำเร็จ กรุณาติดต่อเจ้าหน้าที่')
                    }
                })
        }
    }

    const validateForm1 = () => {
        const error = {};
        const message = {};

        const fieldsToValidate = [
            { en: 'gen_der', th: 'เพศ' },
            { en: 'prefix', th: 'คำนำหน้า' },
            { en: 'firstName', th: 'ชื่อ' },
            { en: 'lastName', th: 'นามสกุล' },
            { en: 'birthDate', th: 'วันเกิด' },
            { en: 'birthMonth', th: 'เดือนเกิด' },
            { en: 'birthYear', th: 'ปีเกิด' },
            { en: 'identity', th: 'บัตรประชาชน' },
            { en: 'residence', th: 'สถานที่อยู่อาศัย' },
            { en: 'email', th: 'อีเมล' },
            { en: 'phone', th: 'โทรศัพท์' },
            { en: 'line', th: 'ไลน์' },
            { en: 'facebook', th: 'เฟซบุ๊ก' },
            { en: 'address', th: 'ข้อมูลที่อยู่' },
            { en: 'province', th: 'จังหวัด' },
            { en: 'county', th: 'เขต/อำเภอ' },
            { en: 'district', th: 'แขวง/ตำบล' },
            { en: 'zipcode', th: 'รหัสไปรษณีย์' },
            { en: 'educational', th: 'ประวัติการศึกษาสูงสุด' }
        ];

        fieldsToValidate.forEach(({ en, th }) => {
            const value = values[en] ? values[en].trim() : '';

            if (!value) {
                error[en] = true;
                message[en] = `กรุณากรอก${th}`;
            } else if (en === 'identity' && !validateIDCard(value)) {
                error[en] = true;
                message[en] = `กรุณากรอก${th}ให้ถูกต้อง`;
            } else if (en === 'phone' && !validPhone.test(value)) {
                error[en] = true;
                message[en] = `กรุณากรอก${th}ให้ถูกต้อง`;
            }
        });

        setIsError({ err: error, message: message });

        const hasErrorValue = Object.values(error).includes(true);
        return hasErrorValue;
    }

    const validateForm2 = () => {
        const error = {};
        const message = {};

        const fieldsToValidate = [
            { en: 'currentAddress', th: 'ข้อมูลที่อยู่' },
            { en: 'currentProvince', th: 'จังหวัด' },
            { en: 'currentCounty', th: 'เขต/อำเภอ' },
            { en: 'currentDistrict', th: 'แขวง/ตำบล' },
            { en: 'currentZipcode', th: 'รหัสไปรษณีย์' },
        ];

        fieldsToValidate.forEach(({ en, th }) => {
            const value = values[en] ? values[en].trim() : '';

            if (value.length === 0) {
                error[en] = true;
                message[en] = `กรุณากรอก${th}`;
            }
        });

        setIsError({ err: error, message: message });

        const hasErrorValue = Object.values(error).includes(true);
        return hasErrorValue;
    }

    const validateForm3 = () => {
        const error = {};
        const message = {};

        const fieldsToValidate = [
            { en: 'compType', th: 'ประเภทกิจการบริษัท/โรงงาน' },
            { en: 'compName', th: 'ชื่อบริษัท/โรงงาน' },
            { en: 'compAddress', th: 'ข้อมูลที่อยู่' },
            { en: 'compProvince', th: 'จังหวัด' },
            { en: 'compCounty', th: 'อำเภอ' },
            { en: 'compDistrict', th: 'ตำบล' },
            { en: 'compZipcode', th: 'รหัสไปรษณีย์' },
            { en: 'careerType', th: 'อาชีพ' },
            { en: 'career', th: 'อาชีพ' },
            { en: 'compPosition', th: 'ตำแหน่ง' },
            { en: 'compEmail', th: 'อีเมล' },
            { en: 'compPhone', th: 'โทรศัพท์' },
            { en: 'mainIncome', th: 'รายได้ (ประจำ)' },
            { en: 'extraIncome', th: 'รายได้ (พิเศษ)' },
            { en: 'incomeSource', th: 'แหล่งที่มาของรายได้' },
            { en: 'part', th: 'ฝ่ายงาน' },
            { en: 'describe_job_duties', th: 'บรรยายหน้าที่การงาน' },
            { en: 'work_age', th: 'อายุงาน' },
        ];

        fieldsToValidate.forEach(({ en, th }) => {
            const value = values[en] ? values[en].trim() : '';

            if (value.length === 0) {
                error[en] = true;
                message[en] = `กรุณากรอก${th}`;
            }
        });

        setIsError({ err: error, message: message });

        const hasErrorValue = Object.values(error).includes(true);
        return hasErrorValue;
    }

    const validateForm4 = () => {
        const error = {};
        const message = {};

        const marry = values?.status === 'สมรส';

        if (marry) {
            const fieldsToValidate = [
                { en: 'spouseName', th: 'ชื่อ' },
                { en: 'spouseSurname', th: 'นามสกุล' },
                { en: 'spouseOldSurname', th: 'นามสกุลเดิม' },
                { en: 'spouseComp', th: 'ชื่อบริษัท/โรงงาน' },
                { en: 'spouseAddress', th: 'ข้อมูลที่อยู่' },
                { en: 'spouseProvince', th: 'จังหวัด' },
                { en: 'spouseZipcode', th: 'รหัสไปรษณีย์' },
                { en: 'spouseCounty', th: 'แขวง/อำเภอ' },
                { en: 'spouseDistrict', th: 'เขต/ตำบล' },
                { en: 'spousePhone', th: 'เบอร์มือถือ' },
                { en: 'spouseCompPhone', th: 'เบอร์โทรที่ทำงาน' },
                { en: 'spouseIncome', th: 'รายได้ต่อเดือน' },
                { en: 'help_the_family', th: 'คู่สมรสช่วยเหลือครอบครัว' },

                { en: 'collectName', th: 'ชื่อผู้รับเงิน' },
                { en: 'collectSurname', th: 'นามสกุลผู้รับเงิน' },
                { en: 'collectPhone', th: 'เบอร์โทรผู้รับเงิน' },
                { en: 'collectRelation', th: 'ความสัมพันธ์กับผู้ขอสินเชื่อ' },
                { en: 'collectComp', th: 'บริษัทผู้รับเงิน' },
            ];

            fieldsToValidate.forEach(({ en, th }) => {
                const value = values[en] ? values[en].trim() : '';

                if (value.length === 0) {
                    error[en] = true;
                    message[en] = `กรุณากรอก${th}`;
                }
                else if (en === 'spousePhone' && !validPhone.test(value)) {
                    error[en] = true;
                    message[en] = `กรุณากรอก${th}ให้ถูกต้อง`;
                }
            });
        } else {
            const fieldsToValidate = [
                { en: 'status', th: 'สถานภาพ' },
                { en: 'collectName', th: 'ชื่อ' },
                { en: 'collectSurname', th: 'นามสกุล' },
                { en: 'collectPhone', th: 'เบอร์โทร' },
                { en: 'collectRelation', th: 'ความสัมพันธ์' },
                { en: 'collectComp', th: 'ชื่อที่ทำงาน' },
            ];

            const resetSpouseFields = {
                spouseName: "",
                spouseSurname: "",
                spouseOldSurname: "",
                spouseComp: "",
                spouseAddress: "",
                spouseProvince: "",
                spouseZipcode: "",
                spouseCounty: "",
                spouseDistrict: "",
                spousePhone: "",
                spouseCompPhone: "",
                spouseIncome: "",
            };

            setValues(prev => ({
                ...prev,
                ...resetSpouseFields
            }));

            fieldsToValidate.forEach(({ en, th }) => {
                const value = values[en] ? values[en].trim() : '';

                if (value.length === 0) {
                    error[en] = true;
                    message[en] = `กรุณากรอก${th}`;
                }
                else if (en === 'status' && value === 'อื่นๆ') {
                    error[en] = true;
                    message[en] = `กรุณากรอก${th}`;
                }
                else if (en === 'collectPhone' && !validPhone.test(value)) {
                    error[en] = true;
                    message[en] = `กรุณากรอก${th}ให้ถูกต้อง`;
                }

            });
        }

        if (values.pdpa === false) {
            error['pdpa'] = true
        }

        setIsError({ err: error, message: message });

        const hasErrorValue = Object.values(error).includes(true);
        return hasErrorValue;
    }

    const validateForm5 = () => {
        const error = {};
        const message = {};

        const fieldsToValidate = [
            { en: 'totalDebt', th: 'ภาระหนี้ทั้งหมด' },
            { en: 'monthlyInstallments', th: 'ผ่อนชำระต่อเดือน' },
            { en: 'amountReq', th: 'จำนวนเงินที่ขอกู้' },
            { en: 'payAble', th: 'ยอดที่ต้องการผ่อนชำระต่อเดือน' },
            { en: 'objective', th: 'วัตถุประสงค์' },
            { en: 'bank', th: 'ธนาคาร' },
            { en: 'account', th: 'เลขบัญชี' },
            { en: 'accounttype', th: 'ประเภทบัญชี' },
            { en: 'accountname', th: 'ชื่อบัญชี' },
        ];

        fieldsToValidate.forEach(({ en, th }) => {
            const value = values[en] ? values[en].trim() : '';

            if (value.length === 0) {
                error[en] = true;
                message[en] = `กรุณากรอก${th}`;
            }
        });

        setIsError({ err: error, message: message });

        const hasErrorValue = Object.values(error).includes(true);
        return hasErrorValue;
    }

    const validateForm6 = () => { }

    const functionMap = {
        1: validateForm1,
        2: validateForm2,
        3: validateForm3,
        4: validateForm4,
        5: validateForm5,
        6: validateForm6,
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => ({ ...prevActiveStep, current: prevActiveStep.current - 1 }));
        window.scrollTo(0, 0)
    };

    const handleForward = () => {
        const selectedFunction = functionMap[activeStep.current];
        const validateForm = selectedFunction

        const hasErrorValue = validateForm()
        if (hasErrorValue) {
            alert('กรุณากรอกข้อมูลให้ถูกต้อง')
            return
        }

        setActiveStep((prevActiveStep) => {
            const newCurrent = prevActiveStep.current + 1;
            if (newCurrent <= 6) {
                return { ...prevActiveStep, current: newCurrent };
            } else {
                // กรณีที่ current เกิน 6 ไม่ต้องเปลี่ยนแปลงข้อมูล
                return prevActiveStep;
            }
        });
        window.scrollTo(0, 0)
    }

    if (isLoading) return <Loading />

    return (
        <div className="center" style={{ maxWidth: '48rem' }}>
            <div style={{ padding: '32px' }}>
                <div className="center-container">
                    <Stack direction="horizontal" gap={3}>
                        <IoIosArrowBack
                            color="white"
                            fontSize={30}
                            onClick={() => activeStep.current > 1 ? handleBack() : null}
                            style={{
                                color: activeStep.current > 1 ? '#fff' : configSource.body.bg_color
                            }}
                        />

                        <div className="header-subject center" style={{ borderRadius: '50px', width: '220px' }}>
                            <p className="p-2 center" style={{ fontWeight: 400, fontSize: 18 }}>
                                ข้อมูลผู้สมัครสินเชื่อ
                            </p>
                        </div>
                        <IoIosArrowForward
                            color="white"
                            fontSize={30}
                            onClick={() => activeStep.current < activeStep.top ? handleForward() : null}
                            style={{
                                color: activeStep.current < activeStep.top ? '#fff' : configSource.body.bg_color
                            }}
                        />
                    </Stack>
                </div>
                <div className="center" style={{ maxWidth: '32rem' }} >
                    <Stepper activeStep={activeStep} />
                </div>

                <br />
                <FormEditor
                    activeStep={activeStep}
                    setActiveStep={setActiveStep}
                    isError={isError}
                    setIsError={setIsError}
                    values={values}
                    setValues={setValues}
                    handleRegister={handleRegister}
                    validateForm1={validateForm1}
                    validateForm2={validateForm2}
                    validateForm3={validateForm3}
                    validateForm4={validateForm4}
                    validateForm5={validateForm5}
                    isLoading={isLoading}
                />
            </div>
        </div>
    )
}

export default Form
