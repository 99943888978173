/* eslint-disable no-unused-vars */
import axios from 'axios';
import config from '../config.json';

const QueryString = require('qs');

export const debitRegister = (lineAccessToken, data) => {
    console.log('debitRegister :', lineAccessToken, data)
    const body = QueryString.stringify(data);
    return axios.post(config.DIRECT_DEBIT_API_URL +'/user/line_register', body, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Bearer ' + lineAccessToken
        },
    })
}

export const changeBankRegister = (lineAccessToken, data) =>{
    const body = QueryString.stringify(data);
    return axios.post(config.DIRECT_DEBIT_API_URL +'/user/change_bank_regsiter', body, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Bearer ' + lineAccessToken
        },
    })
}